import React, { useState, useEffect } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
} from "mdb-react-ui-kit";
import { LoginForm } from "../components/loginForm";
import { RegisterForm } from "../components/registerForm";
import { useParams, useNavigate } from "react-router-dom";
import Logo from "../assets/img/logo-green-color.png";
import { login, registerInitial, requestCodeVerify, recoveryPassword  } from '../controllers/authController';
import Cookies from 'js-cookie';

const Access = ({view}) => {
  const [formType, setFormType] = useState("");
  const [operationSuccess, setOperationSuccess] = useState(false);
  const [loginData, setLoginData] = useState(null);
  const { callback } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Acesso ao Portal - Seguro RCPM';
    if(view){
      setFormType(view);
    }else{
      setFormType('login');
    }
  },[view]);

  useEffect(() => {
    if (operationSuccess) {
      // Armazene os dados em cookies
      Cookies.set('messageWarning', loginData.messageWarning);
      Cookies.set('token', loginData.token);
      Cookies.set('userId', loginData.userId);
      Cookies.set('userName', loginData.userName);
      
      if(callback){
        navigate(callback);
      }else{
        navigate('/');
      }
    }
  }, [operationSuccess, callback, navigate, loginData]);

  return (
    <MDBContainer fluid>
      <MDBRow className="justify-content-center align-items-center container-height-100 bg-login">
        <MDBCol md="5">
          <MDBCard className="card-login">
            <MDBCardBody>
              <MDBCardTitle>
                <img
                  src={Logo}
                  alt="Seguro RCPM"
                  width={"120px"}
                  height={"auto"}
                />
              </MDBCardTitle>
              {formType === "login" ? (
                <LoginForm loginFunction={login} codeResquestFunction={requestCodeVerify} recoveryFunction={recoveryPassword} setStatus={setOperationSuccess} setLoginData={setLoginData}/>
              ) : formType === "register" ? (
                <RegisterForm registerFunction={registerInitial} />
              ) : null}
              <p className="register-account text-center">
                {formType === "login"
                  ? "Ainda não tem uma conta?"
                  : "Já tem uma conta?"}{" "}
                <button
                  onClick={() =>
                    navigate(view === "login" ? "/access/register" : "/access/login")
                  }
                >
                  {formType === "login" ? "Crie uma agora!" : "Faça seu login."}
                </button>
              </p>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default Access;
