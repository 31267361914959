import React, { useState, useEffect, useContext } from "react";
import { NavbarSticky, NavbarLocked } from "../components/navbar";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { SeachPolicy } from "../components/policySearch";
import { Footer } from "../components/footer";
import { ModalPopUpAlertaCampanha } from "../components/modal";
import { fetchApolices } from "../controllers/apolicesController";
import { WarningInfo } from "../components/warningInfo";
import ApoliceContext from "../components/ApoliceContext";
import Cookies from "js-cookie";
import { useSelector, useDispatch } from 'react-redux';
import { setData } from '../controllers/dataSlice'; // Importe a ação do slice

const Home = () => {
  const [status] = useState(false);
  const dispatch = useDispatch();
  const data = useSelector(state => state.data.value);
  const { setDataApolice } = useContext(ApoliceContext);
  const warningCookie = Cookies.get("messageWarning");

  useEffect(() => {
    document.title = "Consulta de Apólices - Seguro RCPM";
    async function fetchData() {
      const response = await fetchApolices();
      dispatch(setData(response)); 
    }
    fetchData();
  }, [dispatch]);

  return (
    <>
      <ModalPopUpAlertaCampanha status={status} />
      {warningCookie === "" ? <NavbarSticky /> : <NavbarLocked />}

      <MDBContainer>
        <MDBRow className="align-items-center">
          <MDBCol md="12" className="home-header">
            <h1 className="mt-3">
              Olá,{" "}
              {Cookies.get("userName") && Cookies.get("userName").split(" ")[0]}{" "}
              😊{" "}
            </h1>
            <h6>
              <i>É ótimo ver você por aqui!</i>{" "}
            </h6>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      {warningCookie === "" ? (
        <SeachPolicy
          data={data}
          viewApolice={setDataApolice}
          cardClass={"card-border_custom mb-3"}
        />
      ) : (
        <WarningInfo info={warningCookie} />
      )}
      <Footer />
    </>
  );
};

export default Home;
