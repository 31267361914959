import { useState } from "react";
import React from "react";
import { MDBBtn, MDBSpinner } from "mdb-react-ui-kit";
import InputMask from "react-input-mask";
import { logout } from "../controllers/authController";

const LoginFairFaxValidator = ({ validatorFunction, warning }) => {
  const [formData, setFormData] = useState({
    LoginFairfax: "",
    TipoCadastro: "",
    Whatsapp: "",
    Telefone: "",
  });

  const [validatorStatus, setValidatorStatus] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState({
    status: false,
    message: "",
  });
  const [validatorStep, setValidatorStep] = useState("initial");

  const handleRegisterLoginFF = async () => {
    setValidatorStatus({ status: "updating" });
    setVerificationStatus({ status: "updating" });

    const register = await validatorFunction(formData);

    console.log(register);

    if (!register.registerStatus) {
      setValidatorStatus({
        status: register.registerStatus,
        error: register.registerError,
      });
    } else {
      
      setValidatorStatus(register);
      setVerificationStatus({ status: false });

      if (register.step === "waiting-LFF") {
        setValidatorStep("waiting-LFF");
        setFormData({ ...formData, LoginFairfax: "" });
      } else {
        if (validatorStep === "code") {
          setValidatorStep("finnaly");
        } else {
          setValidatorStep("code");
        }
      }

    }
  };

  const handleInputChange = (event) => {
    if (event.target.id === "Telefone" || event.target.id === "Whatsapp") {
      const onlyNumbers = event.target.value.replace(/[^\d]/g, "");
      // Atualize o estado com o número limpo
      if (event.target.id === "Telefone") {
        setFormData({ ...formData, Telefone: onlyNumbers });
      } else if (event.target.id === "Whatsapp") {
        setFormData({ ...formData, Whatsapp: onlyNumbers });
      }
    } else {
      // Para outros inputs, atualize o estado normalmente
      setFormData({ ...formData, [event.target.id]: event.target.value });
    }
  };

  const handleNextStep = (step, loginFFType) => {
    if (loginFFType === "create") {
      setFormData({ ...formData, LoginFairfax: "create" });
    }
    setValidatorStep(step);
  };

  return (
    <>
      {validatorStep === "initial" ? (
        <>
          <label className="input-desc" htmlFor="NomeCompleto">
            Informe seu login da seguradora FairFax
          </label>
          <input
            type="text"
            className="form-control border-radius-10 input-access"
            placeholder="Digite aqui seu login da seguradora FairFax"
            id="LoginFairfax"
            value={formData.LoginFairfax}
            onChange={handleInputChange}
          />

          <div className="text-center mt-3">
            {warning === "Login FairFax não localizado." ? (
              <>
                <MDBBtn
                  className="btn-access-area shadow-0"
                  disabled={!formData.LoginFairfax}
                  onClick={handleRegisterLoginFF}
                >
                  {validatorStatus.status === "updating" ? (
                    <MDBSpinner className="me-2" size="sm">
                      <span className="visually-hidden">Loading...</span>
                    </MDBSpinner>
                  ) : (
                    "Adicionar meu login"
                  )}
                </MDBBtn>
                {validatorStatus.status === false && (
                  <p className="hint-access mt-3 text-center">
                    {validatorStatus.error === "Invalid value"
                      ? "Verifique os dados e tente novamente."
                      : validatorStatus.error}
                  </p>
                )}
              </>
            ) : (
              <>
                <MDBBtn
                  className="btn-access-area shadow-0"
                  disabled={!formData.LoginFairfax}
                  onClick={() => handleNextStep("complementary")}
                >
                  Adicionar meu login
                </MDBBtn>
                <p className="hint-login-ff-text">
                  Ainda não tem um login na seguradora FairFax?
                </p>
                <MDBBtn
                  className="btn-access-area-outline shadow-0"
                  onClick={() => handleNextStep("complementary", "create")}
                >
                  Criar meu login FairFax
                </MDBBtn>
              </>
            )}
          </div>
        </>
      ) : validatorStep === "complementary" ? (
        <>
          <p className="hint-complementary">
            Precisamos de informações complementares para finalizar seu
            cadastro.
          </p>
          <label className="input-desc mt-3" htmlFor="TipoCadastro">
            Quem eu sou? *
          </label>
          <select
            defaultValue=""
            className="form-control border-radius-10 input-access"
            value={formData.TipoCadastro}
            onChange={handleInputChange}
            id="TipoCadastro"
          >
            <option value="" disabled>
              Selecione uma opção
            </option>
            <option value="Construtor">Construtor</option>
            <option value="CorrespondenteBancario">
              Correspondente Bancário
            </option>
            <option value="CCA">Correspondente Caixa Aqui</option>
            <option value="CorretorImoveis">Corretor de Imóveis</option>
            <option value="CorretorSeguros">Corretor de Seguros</option>
            <option value="ProprietarioImovel">Proprietário do Imóvel</option>
            <option value="Outros">Outros</option>
          </select>
          <label className="input-desc mt-3" htmlFor="Whatsapp">
            Seu número de WhatsApp
          </label>

          <InputMask
            mask="(99) 9 9999-9999"
            type="text"
            className="form-control border-radius-10 input-access"
            placeholder="Digite aqui seu WhatsApp"
            id="Whatsapp"
            value={formData.Whatsapp}
            onChange={handleInputChange}
          />
          <label className="input-desc mt-3" htmlFor="Telefone">
            Seu número de telefone
          </label>
          <InputMask
            mask="(99) 9999-9999"
            type="text"
            className="form-control border-radius-10 input-access"
            placeholder="Digite aqui seu telefone"
            id="Telefone"
            value={formData.Telefone}
            onChange={handleInputChange}
          />
          <div className="text-center mt-3">
            <p className="hint-complementary-final">
              Campos com "*" são obrigatórios.
            </p>
            <MDBBtn
              className="btn-access-area shadow-0"
              disabled={!formData.TipoCadastro}
              onClick={handleRegisterLoginFF}
            >
              {validatorStatus.status === "updating" ? (
                <MDBSpinner className="me-2" size="sm">
                  <span className="visually-hidden">Loading...</span>
                </MDBSpinner>
              ) : (
                "Concluir Cadastro"
              )}
            </MDBBtn>
            {validatorStatus.status === false && (
              <p className="hint-access mt-3 text-center">
                {validatorStatus.error === "Invalid value"
                  ? "Verifique os dados e tente novamente."
                  : validatorStatus.error}
              </p>
            )}
          </div>
        </>
      ) : validatorStep === "code" ? (
        <>
          <p className="hint-code">
            Para validar seu registro, enviamos um código alfanumérico para seu
            e-mail {validatorStatus.mail} cadastrado no sistema da seguradora
            FairFax, digite-o abaixo e clique em "Validar cadastro" para
            concluir.
          </p>
          <label className="input-desc mt-3" htmlFor="Whatsapp">
            Seu código de verificação
          </label>

          <input
            type="text"
            className="form-control border-radius-10 input-access"
            placeholder="Seu código de verificação aqui"
            id="verificationCode"
            value={formData.verificationCode}
            onChange={handleInputChange}
          />

          <div className="text-center mt-3">
            <MDBBtn
              className="btn-access-area shadow-0"
              disabled={!formData.verificationCode}
              onClick={handleRegisterLoginFF}
            >
              {verificationStatus.status === "updating" ? (
                <MDBSpinner className="me-2" size="sm">
                  <span className="visually-hidden">Loading...</span>
                </MDBSpinner>
              ) : (
                "Validar Cadastro"
              )}
            </MDBBtn>
            {verificationStatus.status === false && (
              <p className="hint-access mt-3 text-center">
                {verificationStatus.error === "Invalid value"
                  ? "Verifique os dados e tente novamente."
                  : verificationStatus.error}
              </p>
            )}
          </div>
        </>
      ) : validatorStep === "waiting-LFF" ? (
        <>
          <p className="hint-code">
            Cadastro no sistema FairFax Realizado com sucesso! Você receberá no e-mail cadastrado em nosso portal o seu login para 
            o sistema FairFax, basta clicar no botão abaixo e informa-lo para realizar sua validação e ter acesso as todos os recursos!
          </p>
          <div className="text-center mt-3">
            <MDBBtn
              className="btn-access-area shadow-0"
              onClick={() => setValidatorStep('initial')}
            >
              Informar meu login FairFax
            </MDBBtn>
          </div>
        </>
      ) : (
        <>
          <p className="hint-code">
            Cadastro válidado com sucesso! Agora basta clicar no botão
            "Desboquear recursos" para ter acesso completo ao portal.
          </p>
          <div className="text-center mt-3">
            <MDBBtn
              className="btn-access-area shadow-0"
              onClick={() => logout()}
            >
              Desboquear recursos
            </MDBBtn>
          </div>
        </>
      )}
    </>
  );
};

export { LoginFairFaxValidator };
