import React, { useState, useEffect } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBBadge,
  MDBListGroup,
  MDBListGroupItem,
  MDBFooter,
  MDBSpinner,
} from "mdb-react-ui-kit";
import DOMPurify from "dompurify";
import {
  getUserNotificationInvoices,
  getUserNotifications,
  viewUserNotification,
} from "../controllers/profileController";
import { addUserToCampaign } from "../controllers/campaingController";

const ModalPopUpAlertaCampanha = ({ status }) => {
  const [staticModal, setStaticModal] = useState(false);

  useEffect(() => {
    setStaticModal(status);
  }, [status]);

  const goTo = (to) => {
    window.location.href = to;
  };

  return (
    <>
      <MDBModal show={staticModal} setShow={setStaticModal}>
        <MDBModalDialog>
          <MDBModalContent className="border-radius-15">
            <img
              src="https://placehold.co/600x400.png"
              alt="campaign-call"
              className="border-radius-15"
              onClick={() => goTo("/campaign")}
            />
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
};

const ModalPopUpAceitarTermos = ({
  status,
  setStatus,
  termsData,
  setAccept,
  consulting,
}) => {
  const [staticModal, setStaticModal] = useState(false);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  const termsDataPurify = { __html: DOMPurify.sanitize(termsData) };

  useEffect(() => {
    setStaticModal(status);
  }, [status]);

  const toggleShow = () => {
    const newStatus = !staticModal;
    setStaticModal(newStatus);
    setStatus(newStatus);
    setIsButtonEnabled(false);
  };

  const handleScroll = (e) => {
    const target = e.target;
    // Verifica se o scroll está próximo do final (dentro de uma margem de 10px, por exemplo)
    const isNearBottom = target.scrollHeight - target.scrollTop <= (target.clientHeight + 10);
    if (isNearBottom) setIsButtonEnabled(true);
  };
  

  const handleRegisterUser = async () => {
    setAccept(true);
    setStaticModal(false);
  };

  return (
    <>
      <MDBModal
        staticBackdrop
        tabIndex="-1"
        show={staticModal}
        setShow={setStaticModal}
      >
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Termos e Condições Gerais de Uso</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleShow}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody
              className="terms-viewer text-center"
              onScroll={handleScroll}
              style={{ overflow: "auto", maxHeight: consulting ? "70vh" : "55vh" }}
            >
              <div dangerouslySetInnerHTML={termsDataPurify} />
            </MDBModalBody>
            {consulting !== true && (
              <MDBModalFooter>
                <p className="account-terms text-justify">
                  Ao clicar em{" "}
                  <b>"Aceito os termos de uso e desejo me registrar"</b> você
                  concorda com nossos <b>termos de uso da plataforma.</b>
                </p>
                <MDBBtn
                  onClick={handleRegisterUser}
                  className="btn-access-area mb-3 d-flex justify-content-center align-items-center"
                  disabled={!isButtonEnabled}
                >
                  Aceito os Termos e Condições Gerais de Uso
                </MDBBtn>
              </MDBModalFooter>
            )}
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
};

const ModalPopUpParticiparCampanha = ({
  status,
  setStatus,
  statusCampanha,
  termsData,
  idCampanha,
  categorias,
  userCategoria,
}) => {
  const [staticModal, setStaticModal] = useState(false);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [categoriaSelecionada, setCategoriaSelecionada] = useState("");
  const [loadingStatus, setLoadingStatus] = useState(false);

  const termsDataPurify = { __html: DOMPurify.sanitize(termsData) };

  useEffect(() => {
    setStaticModal(status);
  }, [status]);

  const toggleShow = () => {
    const newStatus = !staticModal;
    setStaticModal(newStatus);
    setStatus(newStatus);
    setIsButtonEnabled(false);
  };

  const handleScroll = (e) => {
    const target = e.target;
    // Verifica se o scroll está próximo do final (dentro de uma margem de 10px, por exemplo)
    const isNearBottom = target.scrollHeight - target.scrollTop <= (target.clientHeight + 10);
    if (isNearBottom) setIsButtonEnabled(true);
  };

  const handleRegisterUserInCampaign = async () => {
    setLoadingStatus(true);
    setIsButtonEnabled(false);
    const result = await addUserToCampaign(idCampanha, categoriaSelecionada);
    console.log("resultado adição", result);
    if (result.success) {
      setStaticModal(false);
      setLoadingStatus(false);
      alert(
        "Sua inscrição na campanha foi efetuado com sucesso. Em alguns instantes, você receberá um e-mail com mais instruções."
      );
      window.location.reload();
    } else {
      setLoadingStatus(false);
      alert("Houve um erro ao efetuar seu cadastro na campanha.");
      setIsButtonEnabled(true);
    }
  };

  return (
    <>
      <MDBModal
        staticBackdrop
        tabIndex="-1"
        show={staticModal}
        setShow={setStaticModal}
      >
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Regulamento de Campanha</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleShow}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody
              className="terms-viewer text-center"
              onScroll={handleScroll}
              style={{ overflow: "auto", maxHeight: "55vh" }}
            >
              <div dangerouslySetInnerHTML={termsDataPurify} />
            </MDBModalBody>
            {!statusCampanha && (
              <MDBModalFooter>
                <p className="account-terms text-justify">
                  Ao clicar em <b>"Quero participar"</b> você concorda com nosso{" "}
                  <b>Regulamento de Campanha.</b>
                </p>
                <div className="select-categoria-campanha form-component">
                  <select
                    defaultValue={""}
                    onChange={(e) => setCategoriaSelecionada(e.target.value)}
                    disabled={!isButtonEnabled}
                  >
                    <option value={""} disabled>
                      Selecione a categoria que deseja participar
                    </option>
                    {categorias.map((categoria) => (
                      <option key={categoria.id} value={categoria.id}>
                        {categoria.nome}
                      </option>
                    ))}
                  </select>
                </div>
                <MDBBtn
                  onClick={handleRegisterUserInCampaign}
                  className="btn-access-area mb-3 d-flex justify-content-center align-items-center"
                  disabled={!isButtonEnabled || !categoriaSelecionada}
                >
                  {!loadingStatus ? (
                    "Quero participar"
                  ) : (
                    <MDBSpinner role="status">
                      <span className="visually-hidden">Loading...</span>
                    </MDBSpinner>
                  )}
                </MDBBtn>
              </MDBModalFooter>
            )}
            {userCategoria && (
              <MDBFooter>
                <p className="user-cat-participacao">
                  Você está participando desta campanha na categoria
                  <span>{userCategoria}</span>.
                </p>
              </MDBFooter>
            )}
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
};

const ModalPopUpNotificacoes = ({ status, setStatus }) => {
  const [staticModal, setStaticModal] = useState(false);
  const [boletosHoje, setBoletosHoje] = useState(0);
  const [boletosProximosDias, setBoletosProximosDias] = useState(0);
  const [notification, setNotification] = useState([]);

  const setNotificationViewed = async (id) => {
    const response = await viewUserNotification(id);
    if (response) {
      setStatus(false);
    }
  };

  useEffect(() => {
    setStaticModal(status);

    const fetchNotifications = async () => {
      const notificationData = await getUserNotificationInvoices();
      const customNotificationData = await getUserNotifications();
      setNotification(customNotificationData);
      setBoletosHoje(notificationData.countBoletosHoje);
      setBoletosProximosDias(notificationData.countBoletos10Dias);
    };

    fetchNotifications();
  }, [status]);

  const toggleShow = () => {
    const newStatus = !staticModal;
    setStaticModal(newStatus);
    setStatus(newStatus);
  };

  const goTo = (local) => {
    window.location.href = local;
  };

  return (
    <MDBModal
      staticBackdrop
      tabIndex="-1"
      show={staticModal}
      setShow={setStaticModal}
    >
      <MDBModalDialog>
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>
              <span className="icon-circle_line color-default">
                <i className="las la-bell"></i>
              </span>{" "}
              Notificações
            </MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={toggleShow}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBListGroup light style={{ minWidth: "22rem" }}>
            {notification.length < 1 &&
            boletosHoje < 1 &&
            boletosProximosDias < 1 ? (
              <span className="tip-notifications">
                <i className="las la-bell"></i>&nbsp; Você ainda não possui
                nenhuma notificação
              </span>
            ) : (
              <span className="tip-notifications">
                <i className="las la-bell"></i>&nbsp; Confira suas notificações
                abaixo
              </span>
            )}

            {boletosHoje > 0 && (
              <MDBListGroupItem
                className="d-flex cursor-pointer hover-shadow px-3 py-3 notification-container"
                onClick={() => goTo("/invoices/vence_hoje")}
              >
                <MDBBadge
                  pill
                  light
                  className="border-radius-50 icon-notification"
                >
                  <i className="las la-file-invoice-dollar"></i>
                </MDBBadge>
                <div className="text-notification">
                  Você tem {boletosHoje}{" "}
                  {boletosHoje === 1 ? "boleto" : "boletos"} com vencimento hoje
                </div>
              </MDBListGroupItem>
            )}
            {boletosProximosDias > 0 && (
              <MDBListGroupItem
                className="d-flex cursor-pointer hover-shadow px-3 py-3 notification-container"
                onClick={() => goTo("/invoices/vence_5_dias")}
              >
                <MDBBadge
                  pill
                  light
                  className="border-radius-50 icon-notification"
                >
                  <i className="las la-file-invoice-dollar"></i>
                </MDBBadge>
                <div className="text-notification">
                  Você tem {boletosProximosDias}{" "}
                  {boletosProximosDias === 1 ? "boleto" : "boletos"} com
                  vencimento nos próximos 5 dias
                </div>
              </MDBListGroupItem>
            )}
            {notification &&
              notification.map((notification) => (
                <MDBListGroupItem
                  key={notification.ID}
                  className="d-flex cursor-pointer hover-shadow px-3 py-3 notification-container"
                  onClick={() => setNotificationViewed(notification.ID)}
                >
                  <MDBBadge
                    pill
                    light
                    className="border-radius-50 icon-notification"
                  >
                    <i className="las la-comment"></i>
                  </MDBBadge>
                  <div className="text-notification">
                    {notification.Mensagem}
                  </div>
                  <MDBBadge pill light className="icon-trash-notification">
                    <i className="las la-trash"></i>
                  </MDBBadge>
                </MDBListGroupItem>
              ))}
            {/* Inclua outros itens de lista aqui, se necessário */}
          </MDBListGroup>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export {
  ModalPopUpAlertaCampanha,
  ModalPopUpParticiparCampanha,
  ModalPopUpNotificacoes,
  ModalPopUpAceitarTermos,
};
