import React, { useState, useEffect } from "react";
import {
  MDBCol,
  MDBContainer,
  MDBListGroup,
  MDBListGroupItem,
  MDBRow,
  MDBTabs,
  MDBTabsContent,
  MDBTabsItem,
  MDBTabsPane,
  MDBInput,
  MDBBtn,
  MDBTabsLink,
  MDBSpinner,
  MDBBadge,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
} from "mdb-react-ui-kit";
import { NavbarSticky } from "../components/navbar";
import { Footer } from "../components/footer";
import {
  getUserInfo,
  updateUserPassword,
  getAgentLinks,
  addAgentLink,
  revokeAgentLink,
  approveAgentLink,
} from "../controllers/profileController";
import { logout } from "../controllers/authController";
import { updateTime } from "../controllers/apolicesController";
import { formatarDataBrasileira, TermosUso } from "../components/utils";
import { ModalPopUpAceitarTermos } from "../components/modal";

const Profile = () => {
  const [profileActive, setProfileActive] = useState("meus-dados");
  const [connectAccountsActive, setConnectAccountsActive] =
    useState("contas-conectadas");
  const [newPass, setNewPass] = useState("");
  const [lastPass, setLastPass] = useState("");
  const [verifyPass, setVerifyPass] = useState("");
  const [warningPass, setWarningPass] = useState({
    status: false,
    message: "",
  });
  const [statusPass, setStatusPass] = useState({ status: true, message: "" });
  const [loadingPass, setLoadingPass] = useState(false);
  const [profileData, setProfileData] = useState([
    {
      NomeCompleto: "",
      LoginFairfax: "",
      Email: "",
      Telefone: "",
      Celular: "",
      Whatsapp: "",
    },
  ]);
  const [linkData, setLinkData] = useState([]);
  const [linkStep, setLinkStep] = useState("list");
  const [loginLinked, setLoginLinked] = useState("");
  const [linkRequest, setLinkRequest] = useState({
    loading: false,
    message: "",
  });
  const [linkUserData, setLinkUserData] = useState("");
  const [lastUpdate, setLastUpdate] = useState("");
  const [status, setStatus] = useState(false);

  useEffect(() => {
    async function timeTextUpdate() {
      let response = await updateTime();
      setLastUpdate(formatarDataBrasileira(response));
    }
    timeTextUpdate();
  }, []);

  useEffect(() => {
    document.title = "Seu Perfil - Seguros RCPM";

    getLinks("logins-vinculados");
    getLinks("vinculos-ativos");
    getData();
  }, [linkRequest.success]);

  const getData = async () => {
    let response = await getUserInfo();
    if (response && response.length > 0) {
      setProfileData(response);
    }
    console.log(response);
  };

  const getLinks = async (type) => {
    let response = await getAgentLinks(type);
    if (response && response.length > 0) {
      if (type === "logins-vinculados") {
        setLinkData(response);
      } else if (type === "vinculos-ativos") {
        setLinkUserData(response);
      }
    }
    console.log(response);
  };

  const handleProfileClick = (value) => {
    if (value === "desconectar") {
      logout();
    } else if (value === "privacidade") {
      window.open("https://segurorcpm.net.br/privacidade", '_blank');
      return;
    }

    if (value === profileActive) return;

    setProfileActive(value);
  };

  const handleConnectAccountsClick = (value) => {
    if (value === connectAccountsActive) {
      return;
    }

    setConnectAccountsActive(value);
  };

  const handleUpdatePassword = async () => {
    if (loadingPass) {
      return;
    }

    setStatusPass({ status: false, message: "" });

    if (newPass !== verifyPass) {
      setWarningPass({
        status: true,
        message: "As senhas precisam ser iguais.",
      });
      return;
    } else if (newPass.length < 6 || verifyPass.length < 6) {
      setWarningPass({
        status: true,
        message: "Sua nova senha deve ter mais de 6 digítos.",
      });
      return;
    }

    setLoadingPass(true);

    setWarningPass({ status: false, message: "" });

    let response = await updateUserPassword(lastPass, newPass);

    if (response.success) {
      setStatusPass({ status: true, message: "Senha atualizada com sucesso." });
    } else {
      setWarningPass({ status: true, message: response.message });
    }

    setLoadingPass(false);
  };

  const handleCreateLink = async () => {
    try {
      setLinkRequest({ loading: true });

      let response = await addAgentLink(loginLinked);

      console.log(response.message);

      if (!response.success) {
        return setLinkRequest({
          loading: false,
          message: response.message,
          success: false,
        });
      }

      return setLinkRequest({
        loading: false,
        message: response.message,
        success: true,
      });
    } catch (error) {
      console.log(error);
      return setLinkRequest({
        loading: false,
        message: "Houve um erro desconhecido.",
        success: true,
      });
    }
  };

  const handleRevoke = async (loginLinked, type) => {
    try {
      // Adicionando a confirmação aqui
      const confirmRevoke = window.confirm(
        "Tem certeza que deseja revogar este vínculo?"
      );

      if (confirmRevoke) {
        let response = await revokeAgentLink(loginLinked, type);

        if (response.success) {
          if (type === "logins-vinculados") {
            await getLinks("logins-vinculados");
          } else if (type === "vinculos-ativos") {
            await getLinks("logins-ativos");
          } else {
            return false;
          }
        }
      } else {
        console.log("Revoke cancelado pelo usuário.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleApproveLink = async (loginLinked) => {
    try {
      let response = await approveAgentLink(loginLinked);

      if (!response.success) {
        console.log("error", response);
      }

      await getLinks("vinculos-ativos");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <ModalPopUpAceitarTermos
        status={status}
        setStatus={setStatus}
        termsData={TermosUso}
        consulting={true}
      />

      <NavbarSticky local={"profile"} />
      <MDBContainer>
        <MDBRow className="align-items-center mt-5">
          <MDBCol>
            <h4 className="section-title">
              <span className="icon-circle">
                <i className="las la-user-circle"></i>
              </span>
              &nbsp; Minhas Informações
            </h4>
            <p className="section-desc">
              Aqui, você tem acesso total aos seus dados pessoais, podendo
              atualizá-los conforme necessário. Além disso, você pode visualizar
              seus pontos acumulados e, se desejar, alterar a senha de sua conta
              para garantir sua segurança online.
            </p>
          </MDBCol>
        </MDBRow>
        <MDBRow className="justify-content-center align-items-start settings-area">
          <MDBCol md={5}>
            <MDBListGroup light small>
              <MDBTabs>
                <MDBListGroupItem
                  action
                  active={profileActive === "meus-dados"}
                  noBorders
                  className="px-3 list-settings"
                  onClick={() => handleProfileClick("meus-dados")}
                >
                  <MDBTabsItem>
                    <span>
                      <i className="las la-user-edit hidden-on-responsive"></i>
                      &nbsp; Minhas Informações
                    </span>
                  </MDBTabsItem>
                </MDBListGroupItem>
                <MDBListGroupItem
                  action
                  active={profileActive === "logins-conectados"}
                  noBorders
                  className="px-3 list-settings"
                  onClick={() => handleProfileClick("logins-conectados")}
                >
                  <MDBTabsItem>
                    <span>
                      <i className="las la-link hidden-on-responsive"></i>&nbsp;
                      Logins Vínculados
                    </span>
                  </MDBTabsItem>
                </MDBListGroupItem>
                <MDBListGroupItem
                  action
                  active={profileActive === "alterar-senha"}
                  noBorders
                  className="px-3 list-settings"
                  onClick={() => handleProfileClick("alterar-senha")}
                >
                  <MDBTabsItem>
                    <span>
                      <i className="las la-key hidden-on-responsive"></i>&nbsp;
                      Alterar senha
                    </span>
                  </MDBTabsItem>
                </MDBListGroupItem>
                <MDBListGroupItem
                  action
                  noBorders
                  className="px-3 list-settings"
                  onClick={() => handleProfileClick("privacidade")}
                >
                  <MDBTabsItem>
                    <span>
                      <i className="las la-file-invoice hidden-on-responsive"></i>
                      &nbsp; Política de Privacidade
                    </span>
                  </MDBTabsItem>
                </MDBListGroupItem>
                <MDBListGroupItem
                  action
                  noBorders
                  className="px-3 list-settings"
                  onClick={() => setStatus(true)}
                >
                  <MDBTabsItem>
                    <span>
                      <i className="las la-file-invoice hidden-on-responsive"></i>
                      &nbsp; Termos e Condições Gerais de Uso
                    </span>
                  </MDBTabsItem>
                </MDBListGroupItem>
                <MDBListGroupItem
                  action
                  noBorders
                  className="px-3 list-settings"
                  onClick={() => handleProfileClick("desconectar")}
                >
                  <MDBTabsItem>
                    <span>
                      <i className="las la-sign-out-alt hidden-on-responsive"></i>
                      &nbsp; Desconectar
                    </span>
                  </MDBTabsItem>
                </MDBListGroupItem>
              </MDBTabs>
            </MDBListGroup>
          </MDBCol>

          <MDBCol md={7}>
            <MDBTabsContent>
              <MDBTabsPane show={profileActive === "meus-dados"}>
                <div>
                  <h3 className="mb-3">Minhas Informações</h3>
                  <p className="section-desc">
                    Nesta seção, você pode visualizar suas informações pessoais.
                  </p>

                  <MDBInput
                    label="Nome Completo"
                    id="NomeCompleto"
                    value={profileData[0].NomeCompleto}
                    type="text"
                    className="mb-3"
                    disabled
                  />

                  <MDBInput
                    label="Login sistema da seguradora Fairfax"
                    id="LoginFairfax"
                    value={profileData[0].LoginFairfax}
                    type="text"
                    className="mb-3"
                    disabled
                  />

                  <MDBInput
                    label="Endereço de e-mail"
                    id="Email"
                    value={profileData[0].Email}
                    type="email"
                    className="mb-3"
                    disabled
                  />

                  <MDBInput
                    label="Telefone"
                    id="Telefone"
                    value={profileData[0].Telefone}
                    type="tel"
                    className="mb-3"
                    disabled
                  />

                  <MDBInput
                    label="Celular"
                    id="Celular"
                    value={profileData[0].Celular}
                    type="tel"
                    className="mb-3"
                    disabled
                  />

                  <MDBInput
                    label="WhatsApp"
                    id="Whatsapp"
                    value={
                      profileData[0].Whatsapp === 0
                        ? ""
                        : profileData[0].Whatsapp
                    }
                    type="tel"
                    className="mb-3"
                    disabled
                  />
                </div>
              </MDBTabsPane>
              <MDBTabsPane show={profileActive === "logins-conectados"}>
                <MDBTabs className="mb-3">
                  <MDBTabsItem>
                    <MDBTabsLink
                      onClick={() =>
                        handleConnectAccountsClick("contas-conectadas")
                      }
                      active={connectAccountsActive === "contas-conectadas"}
                      className="tabs-conexoes"
                    >
                      <i className="las la-sitemap"></i>&nbsp; Logins Vínculados
                    </MDBTabsLink>
                  </MDBTabsItem>
                  <MDBTabsItem>
                    <MDBTabsLink
                      onClick={() =>
                        handleConnectAccountsClick("conexao-ativa")
                      }
                      active={connectAccountsActive === "conexao-ativa"}
                      className="tabs-conexoes"
                    >
                      <i className="las la-plug "></i>&nbsp; Vínculos Ativos
                    </MDBTabsLink>
                  </MDBTabsItem>
                </MDBTabs>

                <MDBTabsContent>
                  <MDBTabsPane
                    show={connectAccountsActive === "contas-conectadas"}
                  >
                    {linkStep === "add-link" ? (
                      <>
                        <p className="conexoes-section-desc">
                          Para conectar um login e visualizar as apólices da
                          conta vínculada, preencha as informações abaixo e
                          clique em "Solicitar Conexão".
                        </p>
                        <MDBRow className="justify-content-start align-items-center">
                          <MDBCol md="8">
                            <label className="input-desc">
                              Login do usuário no sistema da seguradora FairFax
                            </label>
                            <input
                              type="text"
                              placeholder="Digite aqui o login"
                              className="form-control border-radius-10 input-access mt-1"
                              value={loginLinked}
                              onChange={(e) => setLoginLinked(e.target.value)}
                            />
                            <MDBBtn
                              color="light"
                              rippleColor="dark"
                              className="border_custom btn-default_custom btn-vinculo"
                              onClick={() => handleCreateLink()}
                            >
                              {linkRequest.loading ? (
                                <>
                                  <MDBSpinner className="me-2" size="sm">
                                    <span className="visually-hidden">
                                      Loading...
                                    </span>
                                  </MDBSpinner>
                                  &nbsp; Solicitando Conexão...
                                </>
                              ) : (
                                <>
                                  <i className="las la-plus"></i>&nbsp;
                                  Solicitar Conexão.
                                </>
                              )}
                            </MDBBtn>
                            {!linkRequest.success ? (
                              <p className="hint-access mt-3">
                                {linkRequest.message ===
                                "ER_NO_REFERENCED_ROW_2"
                                  ? "Você só pode víncular logins de usuários cadastrados no siste."
                                  : linkRequest.message === "SAME_ORIGIN"
                                  ? "Você não pode víncular seu próprio login."
                                  : linkRequest.message === "LINK_EXISTS"
                                  ? "Você já fez uma solicitação para esse login."
                                  : linkRequest.message}
                              </p>
                            ) : (
                              <p className="hint-access text-success mt-3">
                                Solicitação enviada com sucesso!
                              </p>
                            )}
                            <p
                              className="account-terms mt-3"
                              style={{ fontWeight: 600 }}
                            >
                              Ao solicitar uma conexão, o usuário com o login
                              fornecido acima deverá autorizar sua conexão. Isso
                              permitirá que você visualize suas apólices.
                            </p>

                            <p className="register-account">
                              <a
                                href="#!"
                                onClick={() => setLinkStep("list-links")}
                              >
                                Voltar a lista de conexões
                              </a>
                            </p>
                          </MDBCol>
                        </MDBRow>
                      </>
                    ) : (
                      <>
                        <p className="conexoes-section-desc">
                          Nesta seção, você pode vincular outros logins à sua
                          conta para visualizar suas apólices vinculadas. Isso é
                          especialmente útil se você é responsável por vários
                          usuários e deseja acessar suas apólices a partir de um
                          único ponto. Para começar, clique no botão "Adicionar
                          Login" e siga as instruções.
                        </p>
                        <div>
                          <div
                            className="d-flex"
                            style={{ justifyContent: "space-between" }}
                          >
                            <MDBBtn
                              color="light"
                              rippleColor="dark"
                              className="border_custom btn-default_custom w-auto btn-vinculo"
                              onClick={() => setLinkStep("add-link")}
                            >
                              <i className="las la-link"></i>&nbsp; Adicionar
                              novo vínculo
                            </MDBBtn>
                            <p className="text-right update-time">
                              Última atualização de dados em {lastUpdate}
                            </p>
                          </div>
                          <MDBTable align="middle">
                            <MDBTableHead>
                              <tr>
                                <th scope="col">Nome</th>
                                <th scope="col">Login</th>
                                <th scope="col">Status</th>
                                <th scope="col"></th>
                              </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                              {linkData &&
                                linkData.map((link) => (
                                  <tr key={link.ID_Vinculo}>
                                    {" "}
                                    <td>
                                      <div className="d-flex align-items-center">
                                        <div className="ms-3">
                                          <p className="fw-bold mb-1">
                                            {link.nome_agente}
                                          </p>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <p className="fw-normal mb-1">
                                        {link.Login_Agente_Vinculado}
                                      </p>
                                    </td>
                                    <td>
                                      <MDBBadge
                                        color={
                                          link.Status === "awaiting approval"
                                            ? "warning"
                                            : link.Status === "authorized"
                                            ? "success"
                                            : "secondary"
                                        }
                                        pill
                                        style={{ fontWeight: 500 }}
                                      >
                                        {link.Status === "awaiting approval"
                                          ? "Aguardando Aprovação"
                                          : link.Status === "authorized"
                                          ? "Autorizado"
                                          : link.Status}
                                      </MDBBadge>
                                    </td>
                                    <td>
                                      <MDBBtn
                                        onClick={() =>
                                          handleRevoke(
                                            link.Login_Agente_Vinculado,
                                            "logins-vinculados"
                                          )
                                        }
                                        color="link"
                                        rounded
                                        size="sm"
                                      >
                                        Cancelar
                                      </MDBBtn>
                                    </td>
                                  </tr>
                                ))}

                              {linkData.length < 1 && (
                                <tr>
                                  <p>Nenhum login conectado.</p>
                                </tr>
                              )}
                            </MDBTableBody>
                          </MDBTable>
                        </div>
                      </>
                    )}
                  </MDBTabsPane>
                  <MDBTabsPane show={connectAccountsActive === "conexao-ativa"}>
                    <p className="conexoes-section-desc">
                      Nesta seção, você tem controle sobre quais logins
                      terceiros têm acesso às apólices que você contratou. Você
                      pode aprovar novos acessos ou revogar acessos existentes.
                      Isso oferece uma camada extra de segurança e
                      transparência, permitindo que você saiba exatamente quem
                      tem acesso às suas informações de apólice.
                    </p>
                    <div>
                      <MDBTable align="middle">
                        <MDBTableHead>
                          <tr>
                            <th scope="col">Nome</th>
                            <th scope="col">Login</th>
                            <th scope="col">Status</th>
                            <th scope="col"></th>
                          </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                          {linkUserData &&
                            linkUserData.map((link) => (
                              <tr key={link.ID_Vinculo}>
                                {" "}
                                <td>
                                  <div className="d-flex align-items-center">
                                    <div className="ms-3">
                                      <p className="fw-bold mb-1">
                                        {link.nome_agente}
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <p className="fw-normal mb-1">
                                    {link.Login_Agente_Principal}
                                  </p>
                                </td>
                                <td>
                                  <MDBBadge
                                    color={
                                      link.Status === "awaiting approval"
                                        ? "warning"
                                        : link.Status === "authorized"
                                        ? "success"
                                        : "secondary"
                                    }
                                    pill
                                    style={{ fontWeight: 500 }}
                                  >
                                    {link.Status === "awaiting approval"
                                      ? "Aguardando Aprovação"
                                      : link.Status === "authorized"
                                      ? "Autorizado"
                                      : link.Status}
                                  </MDBBadge>
                                </td>
                                <td>
                                  {link.Status === "awaiting approval" && (
                                    <MDBBtn
                                      onClick={() =>
                                        handleApproveLink(
                                          link.Login_Agente_Principal
                                        )
                                      }
                                      color="link"
                                      rounded
                                      size="sm"
                                    >
                                      Permitir
                                    </MDBBtn>
                                  )}
                                  <MDBBtn
                                    onClick={() =>
                                      handleRevoke(
                                        link.Login_Agente_Principal,
                                        "vinculos-ativos"
                                      )
                                    }
                                    color="link"
                                    rounded
                                    size="sm"
                                  >
                                    Cancelar
                                  </MDBBtn>
                                </td>
                              </tr>
                            ))}

                          {linkUserData.length < 1 && (
                            <tr>
                              <p>Nenhum login conectado ao sua conta.</p>
                            </tr>
                          )}
                        </MDBTableBody>
                      </MDBTable>
                    </div>
                  </MDBTabsPane>
                </MDBTabsContent>
              </MDBTabsPane>
              <MDBTabsPane show={profileActive === "alterar-senha"}>
                <div>
                  <h3 className="mb-3">Alterar Senha</h3>
                  <p className="section-desc">
                    Para manter sua conta segura, é uma boa prática alterar sua
                    senha regularmente.
                  </p>
                  <MDBInput
                    label="Sua senha atual"
                    id="SenhaAtual"
                    type="password"
                    className="mb-3"
                    value={lastPass}
                    onChange={(e) => setLastPass(e.target.value)}
                  />

                  <MDBInput
                    label="Nova Senha"
                    id="NovaSenha"
                    type="password"
                    className="mb-3"
                    value={newPass}
                    onChange={(e) => setNewPass(e.target.value)}
                  />
                  <MDBInput
                    label="Confirme a Nova Senha"
                    id="ConfirmaNovaSenha"
                    type="password"
                    className="mb-3"
                    value={verifyPass}
                    onChange={(e) => setVerifyPass(e.target.value)}
                  />

                  <MDBBtn
                    color="light"
                    rippleColor="dark"
                    className="btn-default_custom border_custom d-flex justify-content-center align-items-center"
                    onClick={() => handleUpdatePassword()}
                  >
                    {loadingPass ? (
                      <MDBSpinner className="me-2" size="sm">
                        <span className="visually-hidden">Loading...</span>
                      </MDBSpinner>
                    ) : (
                      <i className="las la-sync"></i>
                    )}
                    &nbsp; Alterar minha senha
                  </MDBBtn>

                  {warningPass.status && (
                    <p
                      style={{
                        color: "red",
                        fontWeight: 600,
                        fontSize: "0.8rem",
                        marginTop: "1rem",
                      }}
                    >
                      {warningPass.message}
                    </p>
                  )}
                  {statusPass.status && (
                    <p
                      style={{
                        color: "green",
                        fontWeight: 600,
                        fontSize: "0.8rem",
                        marginTop: "1rem",
                      }}
                    >
                      {statusPass.message}
                    </p>
                  )}
                </div>
              </MDBTabsPane>
            </MDBTabsContent>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <Footer />
    </>
  );
};

export default Profile;
