import config from './config';
import Cookies from 'js-cookie';

const getUserInfo = async () => {
    try {
        // Defina a URL do seu endpoint de busca de apólices
        const url = `${config.apiBaseUrl}/profile/get`;

        // Obtenha o token do cookie
        const token = Cookies.get('token');

        // Defina o cabeçalho Bearer
        const headers = {
            Authorization: `Bearer ${token}`,
        };

        // Configuração da requisição
        const requestOptions = {
            method: 'GET',
            headers: headers,
        };

        // Faça a requisição GET para buscar apólices com o cabeçalho de autorização
        const response = await fetch(url, requestOptions);

        // Verifique se a resposta foi bem-sucedida
        if (response.ok) {
            const result = await response.json();

            if (result.data.success) {
                return result.data.profileInfo;
            } else {
                return [];
            }

        } else {
            // Lida com erros de requisição, se necessário
            console.error('Erro ao buscar dados de usuário:', response.statusText);

            if (response.statusText === 'Forbidden') {
                window.location.href = '/access';
            }
            return [];
        }
    } catch (error) {
        // Lida com erros de requisição, se necessário
        console.error('Erro ao buscar dados de usuário:', error);
        return [];
    }
};

const getUserNotificationInvoices = async () => {
    try {
        // Defina a URL do seu endpoint de busca de apólices
        const url = `${config.apiBaseUrl}/server/notifications/apolices`;

        // Obtenha o token do cookie
        const token = Cookies.get('token');

        // Defina o cabeçalho Bearer
        const headers = {
            Authorization: `Bearer ${token}`,
        };

        // Configuração da requisição
        const requestOptions = {
            method: 'GET',
            headers: headers,
        };

        // Faça a requisição GET para buscar apólices com o cabeçalho de autorização
        const response = await fetch(url, requestOptions);

        // Verifique se a resposta foi bem-sucedida
        if (response.ok) {
            const result = await response.json();

            if (result.success) {
                return result.notification;
            } else {
                return [];
            }

        } else {
            // Lida com erros de requisição, se necessário
            console.error('Erro ao buscar dados de usuário:', response.statusText);

            if (response.statusText === 'Forbidden') {
                window.location.href = '/access';
            }
            return [];
        }
    } catch (error) {
        // Lida com erros de requisição, se necessário
        console.error('Erro ao buscar dados de usuário:', error);
        return [];
    }
};

const getUserNotifications = async () => {
    try {
        // Defina a URL do seu endpoint de busca de apólices
        const url = `${config.apiBaseUrl}/server/notifications`;

        // Obtenha o token do cookie
        const token = Cookies.get('token');

        // Defina o cabeçalho Bearer
        const headers = {
            Authorization: `Bearer ${token}`,
        };

        // Configuração da requisição
        const requestOptions = {
            method: 'GET',
            headers: headers,
        };

        // Faça a requisição GET com o cabeçalho de autorização
        const response = await fetch(url, requestOptions);

        // Verifique se a resposta foi bem-sucedida
        if (response.ok) {
            const result = await response.json();

            if (result.success) {
                return result.notification;
            } else {
                return [];
            }

        } else {
            console.error('Erro ao buscar dados de usuário:', response.statusText);

            if (response.statusText === 'Forbidden') {
                window.location.href = '/access';
            }
            return [];
        }
    } catch (error) {
        // Lida com erros de requisição, se necessário
        console.error('Erro ao buscar dados de usuário:', error);
        return [];
    }
};

const viewUserNotification = async (id) => {
    try {
        // Defina a URL do seu endpoint de busca de apólices
        const url = `${config.apiBaseUrl}/server/notification/${id}/view`;

        // Obtenha o token do cookie
        const token = Cookies.get('token');

        // Defina o cabeçalho Bearer
        const headers = {
            Authorization: `Bearer ${token}`,
        };

        // Configuração da requisição
        const requestOptions = {
            method: 'POST',
            headers: headers,
        };

        // Faça a requisição POST com o cabeçalho de autorização
        const response = await fetch(url, requestOptions);

        // Verifique se a resposta foi bem-sucedida
        if (response.ok) {
            const result = await response.json();

            if (result.success) {
                return true;
            } else {
                return false;
            }

        } else {
            console.error('Erro ao buscar dados de usuário:', response.statusText);

            if (response.statusText === 'Forbidden') {
                window.location.href = '/access';
            }
            return false;
        }
    } catch (error) {
        // Lida com erros de requisição, se necessário
        console.error('Erro ao buscar dados de usuário:', error);
        return false;
    }
};

const updateUserPassword = async (lastPass, newPass) => {
    try {
        // Defina a URL do seu endpoint de busca de apólices
        const url = `${config.apiBaseUrl}/profile/password/update`;

        //console.log('senha nova:', newPass);

        const data = {
            lastPass,
            newPass
        };

        // Obtenha o token do cookie
        const token = Cookies.get('token');

        // Defina o cabeçalho Bearer
        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        };

        // Configuração da requisição
        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data),
        };
        const response = await fetch(url, requestOptions);

        // Verifique se a resposta foi bem-sucedida
        if (response.ok) {
            const result = await response.json();

            if(result.success){
                return {success: true, message: result.message};
            }

        } else {
            // Lida com erros de requisição, se necessário
            console.error('Erro ao atualizar senha de usuário:', response.statusText);

            if (response.statusText === 'Forbidden') {
                window.location.href = '/access';
            }else{
                let errorResponse = await response.json();
                return {success: false, message: errorResponse.message};
            }
        }
    } catch (error) {
        // Lida com erros de requisição, se necessário
        console.error('Erro ao buscar dados de usuário:', error);        
    }
};

const getAgentLinks = async (type) => {
    try {
        
        let url;

        if(type === 'logins-vinculados'){
            url = `${config.apiBaseUrl}/profile/links/get`;
        }else if(type === 'vinculos-ativos'){
            url = `${config.apiBaseUrl}/profile/links/user/get`;
        }else{
            return false;
        }

        // Obtenha o token do cookie
        const token = Cookies.get('token');

        // Defina o cabeçalho Bearer
        const headers = {
            Authorization: `Bearer ${token}`,
        };

        // Configuração da requisição
        const requestOptions = {
            method: 'GET',
            headers: headers,
        };

        // Faça a requisição GET para buscar os links do agente com o cabeçalho de autorização
        const response = await fetch(url, requestOptions);

        // Verifique se a resposta foi bem-sucedida
        if (response.ok) {
            const result = await response.json();

            console.log('links', result);

            if (result.data) {
                return result.data;
            } else {
                return [];
            }

        } else {
            // Lida com erros de requisição, se necessário
            console.error('Erro ao buscar links do agente:', response.statusText);

            if (response.statusText === 'Forbidden') {
                window.location.href = '/access';
            }
            return [];
        }
    } catch (error) {
        // Lida com erros de requisição, se necessário
        console.error('Erro ao buscar links do agente:', error);
        return [];
    }
};

const addAgentLink = async (login) => {
    try {
        // Defina a URL do seu endpoint de busca de apólices
        const url = `${config.apiBaseUrl}/profile/links/create`;

        console.log('login:', login);

        const data = {
            login
        };

        // Obtenha o token do cookie
        const token = Cookies.get('token');

        // Defina o cabeçalho Bearer
        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        };

        // Configuração da requisição
        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data),
        };
        const response = await fetch(url, requestOptions);

        // Verifique se a resposta foi bem-sucedida
        if (response.ok) {
            const result = await response.json();

            if(result.status === 'created'){
                return {success: true, message: 'Solicitação de vínculo enviada com sucesso.'};
            }else{
                console.log(result.error);
                return { success: false, message: result.error}
            }

        } else {
            // Lida com erros de requisição, se necessário
            console.error('Erro ao atualizar senha de usuário:', response.statusText);

            if (response.statusText === 'Forbidden') {
                window.location.href = '/access';
            }else{
                return {success: false, message: 'Houve um erro, tente novamente.'};
            }
            
        }
    } catch (error) {
        // Lida com erros de requisição, se necessário
        console.error('Erro ao buscar dados de usuário:', error);        
    }
};

const revokeAgentLink = async (login, type) => {
    try {
        let url;

        if(type === 'logins-vinculados'){
            url = `${config.apiBaseUrl}/profile/links/revoke/other`;
        }else if(type === 'vinculos-ativos'){
            url = `${config.apiBaseUrl}/profile/links/revoke/user`;
        }else{
            return false;
        }

        const data = {
            login
        };

        // Obtenha o token do cookie
        const token = Cookies.get('token');

        // Defina o cabeçalho Bearer
        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        };

        // Configuração da requisição
        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data),
        };
        const response = await fetch(url, requestOptions);

        // Verifique se a resposta foi bem-sucedida
        if (response.ok) {
            const result = await response.json();

            if(result.status === 'revoked'){
                return {success: true, message: 'Vínculado cancelado com sucesso.'};
            }else{
                return {success: false, message: 'Houve um erro ao cancelar o vínculo.'};
            }

        } else {

            if (response.statusText === 'Forbidden') {
                window.location.href = '/access';
            }else{
                return {success: false, message: 'Houve um erro, tente novamente.'};
            }
            
        }
    } catch (error) {
        // Lida com erros de requisição, se necessário
        console.error('Erro ao buscar dados de usuário:', error);        
    }
};

const approveAgentLink = async (login) => {
    try {
        
        const url = `${config.apiBaseUrl}/profile/links/approve`;

        console.log('login:', login);

        const data = {
            login
        };

        // Obtenha o token do cookie
        const token = Cookies.get('token');

        // Defina o cabeçalho Bearer
        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        };

        // Configuração da requisição
        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data),
        };
        const response = await fetch(url, requestOptions);

        // Verifique se a resposta foi bem-sucedida
        if (response.ok) {
            const result = await response.json();

            if(result.status === 'authorized'){
                return {success: true, message: 'Vínculado cancelado com sucesso.'};
            }else{
                return {success: false, message: 'Houve um erro ao cancelar o vínculo.'};
            }

        } else {
            // Lida com erros de requisição, se necessário
            console.error('Erro ao atualizar senha de usuário:', response.statusText);

            if (response.statusText === 'Forbidden') {
                window.location.href = '/access';
            }else{
                return {success: false, message: 'Houve um erro, tente novamente.'};
            }
            
        }
    } catch (error) {
        // Lida com erros de requisição, se necessário
        console.error('Erro ao buscar dados de usuário:', error);        
    }
};

export { getUserInfo, updateUserPassword, viewUserNotification, getUserNotificationInvoices, getUserNotifications, getAgentLinks, addAgentLink, revokeAgentLink, approveAgentLink };