import { useState, useEffect } from "react";
import React from "react";
import { MDBBtn, MDBSpinner } from "mdb-react-ui-kit";
import InputMask from "react-input-mask";
import { ModalPopUpAceitarTermos } from "./modal";
import { TermosUso } from "./utils";

const RegisterForm = ({ registerFunction }) => {
  const [formData, setFormData] = useState({
    NomeCompleto: "",
    Password: "",
    rePassword: "",
    Email: "",
    Celular: "",
    CPF: "",
  });
  const [registerStatus, setRegisterStatus] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [ status, setStatus ] = useState(false);
  const [ accept, setAccept ] = useState(false);

  const handleRegister = async () => {
    const register = await registerFunction(formData);

    if (!register.registerStatus) {
      setRegisterStatus({
        status: register.registerStatus,
        error: register.registerError,
      });
      console.log(register);
    } else {
      setRegisterStatus({ status: "success" });
      window.location.href = "/access";
    }
  };

  const handleInputChange = (event) => {
    // Se o ID do evento for 'Celular', limpe a formatação
    if (event.target.id === "Celular" || event.target.id === "CPF") {
      const onlyNumbers = event.target.value.replace(/[^\d]/g, "");
      // Atualize o estado com o número limpo
      if (event.target.id === "Celular") {
        setFormData({ ...formData, Celular: onlyNumbers });
      } else if (event.target.id === "CPF") {
        setFormData({ ...formData, CPF: onlyNumbers });
      }
    } else {
      // Para outros inputs, atualize o estado normalmente
      setFormData({ ...formData, [event.target.id]: event.target.value });
    }
  };

  const canSubmit = () => {
    return (
      formData.NomeCompleto &&
      formData.Password &&
      formData.Password === formData.rePassword &&
      formData.Email &&
      formData.Celular &&
      formData.CPF
    );
  };

  useEffect(() => {
    // Este efeito colateral irá rodar sempre que `formData.Password` ou `formData.rePassword` mudar.
    const passwordsMatch = formData.Password === formData.rePassword;
    setPasswordError(!passwordsMatch);
  }, [formData.Password, formData.rePassword]); // Dependências do efeito colateral

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.Password !== formData.rePassword) {
      setPasswordError(true);
    } else if(accept === false){
      setStatus(true);
    }else {
      setPasswordError(false);
      setRegisterStatus({status: 'registering'});
      await handleRegister();
    }
  };

  return (
    <>
      <ModalPopUpAceitarTermos status={status} setStatus={setStatus} termsData={TermosUso} setAccept={setAccept} />
      <p className="text-access-desc mt-3">
        Para criar sua conta no nosso site preencha as informações solicitadas
        abaixo.
      </p>

      <label className="input-desc" htmlFor="NomeCompleto">
        Nome Completo
      </label>
      <input
        type="text"
        className="form-control border-radius-10 input-access"
        placeholder="Digite aqui seu nome completo"
        id="NomeCompleto"
        value={formData.NomeCompleto}
        onChange={handleInputChange}
      />

      <label className="input-desc mt-3" htmlFor="Password">
        Senha
      </label>
      <input
        type="password"
        className="form-control border-radius-10 input-access"
        placeholder="Digite aqui sua senha"
        id="Password"
        value={formData.Password}
        onChange={handleInputChange}
      />

      <label className="input-desc mt-3" htmlFor="Password">
        Digite novamente sua senha
      </label>
      <input
        type="password"
        className="form-control border-radius-10 input-access"
        placeholder="Digite novamente sua senha"
        id="rePassword"
        value={formData.rePassword}
        onChange={handleInputChange}
      />

      {passwordError && (
        <span className="hint-access mt-2">As senhas não coincidem.</span>
      )}

      <label className="input-desc mt-3" htmlFor="Email">
        E-mail
      </label>
      <input
        type="email"
        className="form-control border-radius-10 input-access"
        placeholder="Digite aqui seu e-mail"
        id="Email"
        value={formData.Email}
        onChange={handleInputChange}
      />

      <label className="input-desc mt-3" htmlFor="CPF">
        CPF
      </label>
      <InputMask
        mask="999.999.999-99"
        className="form-control border-radius-10 input-access"
        placeholder="Digite aqui seu CPF"
        id="CPF"
        value={formData.CPF}
        onChange={handleInputChange}
      />

      <label className="input-desc mt-3" htmlFor="Celular">
        Celular
      </label>
      <InputMask
        mask="(99) 99999-9999"
        className="form-control border-radius-10 input-access"
        placeholder="Digite aqui seu número de celular"
        id="Celular"
        value={formData.Celular}
        onChange={handleInputChange}
      />

      <div className="text-center mt-3">
        <MDBBtn
          className="btn-access-area shadow-0"
          disabled={!canSubmit()}
          onClick={handleSubmit}
        >
          {registerStatus.status === "registering" ? (
            <MDBSpinner className="me-2" size="sm">
              <span className="visually-hidden">Loading...</span>
            </MDBSpinner>
          ) : (
            "Criar minha conta"
          )}
        </MDBBtn>
        {registerStatus.status === false && (
          <p className="hint-access mt-3 text-center">
            {registerStatus.error === "Invalid value"
              ? "Verifique os dados e tente novamente."
              : registerStatus.error}
          </p>
        )}
        <p className="account-terms register-account mt-3">
          Ao criar sua conta em nosso site você concorda com nossos{" "}
          <a href="#!">Termos de Uso</a> e{" "}
          <a href="https://www.segurorcpm.net.br/privacidade" target="_blank" rel='noreferrer'>Política de Privacidade</a>.
        </p>
      </div>
    </>
  );
};

export { RegisterForm };
