import React, { useState } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBListGroup,
  MDBListGroupItem,
  MDBBadge,
} from "mdb-react-ui-kit";
import { formatarDataBrasileira } from "./utils";
import { ModalPopUpParticiparCampanha } from "./modal";

const CampaingListActive = ({ data }) => {
  const [activeFilters, setActiveFilters] = useState([]);
  const [showTerms, setShowTerms] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [termsData, setTermsData] = useState('')
  const [categorias, setCategorias] = useState([]);
  const [CampaignId, setCampaignId] = useState();
  const [userCategoria, setUserCategoria] = useState('');

  const toggleFilter = (filterName) => {
    const newFilters = activeFilters.includes(filterName)
      ? activeFilters.filter((f) => f !== filterName)
      : [...activeFilters, filterName];
    setActiveFilters(newFilters);
  };

  const filteredData = data.filter((item) => {
    if (activeFilters.length === 0) return true;

    const partipationFilter = activeFilters.includes("Estou Inscrito");
    const availableFilter = activeFilters.includes("Ativa");
    const closedFilter = activeFilters.includes("Encerrada");

    const meetsParticipationCondition = !partipationFilter || (partipationFilter && item.EstouInscrito === true);
    const meetsStatusCondition = (!availableFilter && !closedFilter) ||
      (availableFilter && item.status === "Ativa") ||
      (closedFilter && item.status === "Encerrada");

    return meetsParticipationCondition && meetsStatusCondition;
  });

  const handleCampaignClick = (item) => {
    if (item.EstouInscrito === false && item.status === "Ativa") {
      setSelectedCampaign(false);
    } else {
      setSelectedCampaign(true);
    }
    // Atualizar as categorias no estado
    const categoriasArray = item.categorias_campanha.split(",").map((nomeCategoria, index) => ({
      id: item.id_categoria_campanha.split(",")[index],
      nome: nomeCategoria
    }));

    setUserCategoria(item.nm_categoria_usuario);
    setCampaignId(item.id_campanha);
    setCategorias(categoriasArray);
    console.log(item);
    setTermsData(item.link_texto_regulamento);
    setShowTerms(true);
  };

  const filterButtons = ["Estou Inscrito", "Ativa", "Encerrada"];

  return (
    <MDBContainer>
      <ModalPopUpParticiparCampanha
        status={showTerms}
        setStatus={setShowTerms}
        statusCampanha={selectedCampaign}
        termsData={termsData}
        categorias={categorias}
        idCampanha={CampaignId}
        userCategoria={userCategoria}
      />

      <MDBRow className="justify-content-center mt-5">
        <MDBCol>
          <MDBCard className="card-border_custom mb-5">
            <MDBCardBody>
              <MDBRow className="mb-1">
                <MDBCol>
                  <h4 className="card-title_custom">
                    <span className="icon-circle_line">
                      <i className="las la-gift"></i>
                    </span>{" "}
                    Campanhas
                  </h4>
                  <p className="tip-text">
                    Visualize suas campanhas e aquelas disponíveis para sua inscrição. Utilize as opções desejadas abaixo para aplicar os filtros de busca e selecione a campanha que deseja participar ou acessar o regulamento.
                  </p>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  {filterButtons.map((btn) => (
                    <button
                      key={btn}
                      className={`btn btn-invoice_filter shadow-0 ${
                        activeFilters.includes(btn) ? "active" : ""
                        }`}
                      onClick={() => toggleFilter(btn)}
                    >
                      {btn}
                    </button>
                  ))}
                </MDBCol>
              </MDBRow>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>

      <MDBRow className="justify-content-center">
        <MDBCol>
          <MDBListGroup style={{ minWidth: "22rem" }}>
            {filteredData.map((item, index) => (
              <MDBListGroupItem
                className="d-flex justify-content-between align-items-center cursor-pointer hover-shadow"
                key={index}
                onClick={() => handleCampaignClick(item)}
              >
                <div className="w-100">
                  <div className="fw-bold text-truncate">
                    <MDBRow>
                      <MDBCol>
                        <MDBBadge
                          pill
                          light
                          color={
                            item.EstouInscrito === true
                              ? "primary"
                              : item.EstouInscrito === false &&
                                item.status === "Ativa"
                                ? "success"
                                : "danger"
                          }
                        >
                          {item.EstouInscrito === true
                            ? "Já participo"
                            : item.EstouInscrito === false &&
                              item.status === "Ativa"
                              ? "Disponível para participação"
                              : "Campanha indisponível"}
                        </MDBBadge>
                      </MDBCol>
                    </MDBRow>
                  </div>
                  <div className="text-muted list-view_custom mt-2 mb-2">
                    <MDBRow>
                      <MDBCol>
                        <span>
                          <b>Campanha:</b> <br />
                          {item.nm_titulocampanha || "N/A"}
                        </span>
                      </MDBCol>
                      <MDBCol>
                        <span>
                          <b>Início:</b> <br />
                          {formatarDataBrasileira(item.dt_iniciovigencia) || "N/A"}
                        </span>
                      </MDBCol>
                      <MDBCol>
                        <span className="text-truncate">
                          <b>Fim:</b> <br />
                          {formatarDataBrasileira(item.dt_terminovigencia) || "N/A"}
                        </span>
                      </MDBCol>
                      <MDBCol>
                        <span>
                          <b>Regulamento:</b> <br />
                          Clique para visualizar
                        </span>
                      </MDBCol>
                      <MDBCol>
                        <span>
                          <b>Categorias disponíveis:</b> <br />
                            {item.categorias_campanha}
                        </span>
                      </MDBCol>
                    </MDBRow>
                  </div>
                </div>
                <MDBBadge
                  pill
                  light
                  color={
                    item.status === "Ativa"
                      ? "success"
                      : item.status === "Encerrada" || item.status === "Inativa"
                        ? "primary"
                        : "primary"
                  }
                  className="text-uppercase"
                >
                  {item.status === "Ativa"
                    ? "Ativa"
                    : item.status === "Encerrada" || item.status === "Inativa" 
                      ? "Encerrada"
                      : item.status}
                </MDBBadge>
              </MDBListGroupItem>
            ))}
          </MDBListGroup>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export { CampaingListActive };
