const formatarDataBrasileira = (dataString) => {
  // Verifica se dataString é uma string
  if (typeof dataString !== 'string') {
    return "---";
  }

  const intervalo = dataString.split(" - ");

  // Verificar se é um intervalo de datas
  if (intervalo.length === 2) {
    const dataInicial = formatDate(intervalo[0]);
    const dataFinal = formatDate(intervalo[1]);

    if (dataInicial && dataFinal) {
      return `${dataInicial} - ${dataFinal}`;
    }
  } else {
    const dataFormatada = formatDate(dataString);
    return dataFormatada || "---";
  }

  return "---";
};

const formatDate = (dataISO) => {
  if (!dataISO || typeof dataISO !== 'string') {
    return null;
  }

  // Extrai apenas a data da string ISO, ignorando a hora
  const [data] = dataISO.split('T');
  if (!data) {
    return null;
  }

  const [ano, mes, dia] = data.split('-');

  // Verifica se as partes são números válidos
  if (isNaN(ano) || isNaN(mes) || isNaN(dia)) {
    return null;
  }

  // Cria um objeto de data com os valores ano, mes e dia
  const dataObjeto = new Date(ano, mes - 1, dia); // mês é 0-indexed

  // Retorna a data formatada no formato brasileiro
  return dataObjeto.toLocaleDateString("pt-BR");
};

const formatarCNPJ = (cnpj) => {
  if (!cnpj) {
    return "---";
  }

  // Remove caracteres não numéricos
  cnpj = cnpj.replace(/\D/g, '');

  // Aplica a máscara de formatação
  return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5");
};

function getCookie(nomeDoCookie) {
  var cookies = document.cookie.split(';');
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i].trim();
    if (cookie.indexOf(nomeDoCookie + '=') === 0) {
      return cookie.substring(nomeDoCookie.length + 1, cookie.length);
    }
  }
  return null; // Retorna nulo se o cookie não for encontrado
}

function truncarTexto(texto, comprimentoMaximo) {
  if(texto){
    if (texto.length > comprimentoMaximo) {
        return texto.substring(0, comprimentoMaximo) + '...';
    } else {
        return texto;
    }
  }else{
    return texto;
  }
}

const TermosUso = `<h6 class="text-center mb-3"><b>TERMOS E CONDIÇÕES GERAIS DE USO</b></h6>

<p>Os termos e condições gerais de uso especificados abaixo e denominados “Termos de Uso” ou “Contrato” aplicam-se ao uso dos serviços oferecidos pela empresa PLUG CORRETORA DE SEGUROS LTDA. (“PLUG”), inscrita no CNPJ no. 25.320.170/0001-84, por meio de endereços web (“Sites”) ou aplicativos (“Aplicativos”) administrados direta ou indiretamente pela PLUG.</p>

<p>Qualquer pessoa que pretenda usar os serviços dos Sites ou Aplicativos, doravante denominada “Usuário”, deverá aceitar os Termos de Uso aqui descritos.</p>

<p>Ao acessar os Sites e Aplicativos, você, Usuário, expressamente aceita e concorda com as condições destes Termos de Uso. Por este motivo, recomendamos que você sempre leia atentamente todas as condições descritas nestes Termos de Uso, assim como, em outros documentos, páginas web ou termos adicionais que eventualmente acompanhem a contratação dos serviços e/ou produtos ofertados em nossos Sites e Aplicativos.</p>

<p>CASO VOCÊ NÃO CONCORDE COM OS TERMOS DE USO, VOCÊ NÃO DEVE USAR OS NOSSOS SERVIÇOS NOS SITES E APLICATIVOS DA PLUG.</p>

<h6>1. Aceitação dos Termos de Uso</h2>
<p>1.1 Para realizar o acesso aos serviços e/ou produtos ofertados em cada um de nossos Sites e Aplicativos, o Usuário deverá realizar um cadastro específico para criar seu Login e Senha em cada Site ou Aplicativo. Neste momento deverá realizar o aceite dos Termos de Uso no próprio Site ou Aplicativo que estiver utilizando.</p>

<p>1.2 Ao aceitar os Termos de Uso, você expressamente e de forma livre e inequívoca, declara que teve acesso aos Termos de Uso, e que leu, entendeu e concorda com as condições nele descritas.</p>

<h6>2. Sobre nossa Política de Privacidade</h2>
<p>2.1 Quaisquer dados pessoais fornecidas no ato de cadastro do Usuário e/ou durante a utilizações dos serviços de nossos Sites e Aplicativos serão tratadas conforme nossa Política de Privacidade.</p>

<p>2.2 Você pode conferir como seus dados pessoais são tratados em nossa Política de Privacidade na página principal de cada Site ou Aplicativo em uso, ou através da página da PLUG em <a href="https://www.plugseguros.com.br/privacidade">https://www.plugseguros.com.br/privacidade</a>.</p>

<h6>3. Como nossos Sites e Aplicativos não devem ser utilizados</h2>
<p>3.1 Quando utilizar nossos Sites e Aplicativos, você se compromete a:</p>
<ul style='text-align: left;'>
    <li>a. Não praticar qualquer ilícito, violar a legislação vigente ou direitos da PLUG, de terceiros ou de outros Usuários;</li>
    <li>b. Não praticar atos que prejudiquem os Sites, Aplicativos ou equipamentos da PLUG, de terceiros ou de outros Usuários por meio do uso de vírus, bots, programas maliciosos ou qualquer outro meio para este fim;</li>
    <li>c. Não utilizar identificação de um terceiro ou permitir que terceiros utilizem a sua identificação para acesso aos nossos Sites e Aplicativos;</li>
    <li>d. Não inserir nos Sites e Aplicativos qualquer conteúdo ilícito ou que viole direitos de terceiros;</li>
</ul>

<p>3.2 Você reconhece que, em qualquer hipótese, será o único responsável pelo uso que fizer dos nossos Sites e Aplicativos, bem como por qualquer conteúdo que tenha sido inserido ou informado por você, isentando a PLUG de qualquer responsabilidade por danos e prejuízos decorrentes do uso indevido dos meios de comunicação disponibilizados através de nossos Sites e Aplicativos.</p>

<h6>4. Responsabilidades</h2>
<p>4.1 Você, como Usuário de nossos Sites e Aplicativos, é responsável por:</p>
<ul style='text-align: left;'>
    <li>a. Todas as suas ações e omissões realizadas nos nossos Sites e Aplicativos;</li>
    <li>b. Pela reparação de danos causados à PLUG, terceiros e a outros Usuários, a partir do uso de nossos Sites e Aplicativos;</li>
</ul>

<p>4.2. Você declara estar ciente de que a PLUG não de responsabilizará, em nenhuma hipótese, por perdas e dados de qualquer natureza, inclusive lucros cessantes, causados nos seguintes casos:</p>
<ul style='text-align: left;'>
    <li>a. Por indisponibilidades, erros e falhas técnicas, de qualquer natureza, dos Sites e Aplicativos;</li>
    <li>b. Por indisponibilidades de uso e dificuldade de acesso aos Sites e Aplicativos;</li>
    <li>c. Por dados desatualizados, imprecisos, incompletos ou inverídicos disponibilizados e atualizados pelo Usuário;</li>
    <li>d. Pela presença de vírus ou de ataques de hackers ou terceiros;</li>
    <li>e. Por ações ou omissões causados pelo Usuário ou por terceiros.</li>
</ul>

<h6>5. Propriedade Intelectual</h2>
<p>5.1 Os seguintes itens constituem propriedade intelectual da PLUG e somente podem ser utilizados com a sua prévia e expressa autorização:</p>
<ul style='text-align: left;'>
    <li>a. As páginas, identidade visual, funcionalidades e conteúdos incluindo, sem limitações, textos, imagens, gráficos, desenhos, áudios e vídeos, dos Sites e Aplicativos;</li>
    <li>b. Nomes de domínios, empresas, produtos, as marcas e patentes, assim como ícones, logos, slogans, anúncios, peças de publicidade ou qualquer sinal distintivo utilizado pelos Sites e Aplicativos.</li>
</ul>

<p>5.2 O Usuário concorda em não reproduzir de nenhuma forma os Sites ou Aplicativos, seja total ou parcialmente, temporária ou provisoriamente, de forma gratuita ou onerosa.</p>

<h6>6. Rescisão dos Termos de Uso e Suspensão de Acesso</h2>
<p>6.1 Estes Termos de Uso estarão vigentes enquanto o Usuário fizer uso dos serviços em nossos Sites e Aplicativos.</p>

<p>6.2 Você concorda que a PLUG pode, a seu exclusivo critério e sem aviso prévio ou posterior, suspender ou cancelar o seu acesso aos Sites e/ou Aplicativos em qualquer dos seguintes casos, mas não se limitando a:</p>
<ul style='text-align: left;'>
    <li>a. Por descumprimento e/ou violação destes Termos de Uso;</li>
    <li>b. Em função de ordem judicial e/ou requisição legal e competente de autoridade governamental;</li>
    <li>c. Por solicitação do próprio Usuário;</li>
    <li>d. Por descontinuação ou modificação de algum serviço dos Sites ou Aplicativos;</li>
    <li>e. Por caso fortuito, força maior e/ou questões de segurança;</li>
    <li>f. Por inatividade da conta por um longo período, quando aplicável;</li>
    <li>g. Pela prática de qualquer atividade fraudulenta ou ilegal por parte do Usuário, a critério da PLUG e conforme previsto nestes Termos de Uso e na legislação vigente;</li>
    <li>h. Pelo uso inadequado dos Sites ou Aplicativos, a critério da PLUG;</li>
    <li>i. Por inadimplemento por parte do Usuário de quaisquer de suas obrigações, incluindo valores e pagamentos devidos em razão do uso dos serviços e/ou produtos fornecidos pelos Sites e Aplicativos, quando aplicável.</li>
</ul>

<h6>7. Alterações dos Termos de Uso</h2>
<p>7.1 Estes Termos de Uso, assim com as informações e funcionalidades presentes nos nossos Sites e Aplicativos poderão ser atualizadas, modificadas ou extintas a qualquer tempo e sem aviso prévio pela PLUG sempre que esta entender necessário, seja por razões legais, regulatórias, atualização tecnológica, melhorias dos serviços ou outras razões.</p>

<p>7.2 A PLUG dedicará, a seu critério, seus melhores esforços para notificar os Usuários sobre eventuais mudanças que ocorram nos Termos de Uso, no entanto, cabe a você verificar periodicamente se está de acordo com a versão mais atualizada deste documento nos Sites e Aplicativos.</p>

<p>7.3 O Usuário poderá a qualquer momento revisar a versão mais recente destes Termos de Uso, acessando a opção "Termos e Condições Gerais de Uso" localizada no menu dos Aplicativos ou Sites.</p>

<p>7.4 O uso continuado do Usuário após a publicação nos Sites ou Aplicativos de qualquer atualização dos Termos de Uso representa um aceite tácito à versão mais recente deste documento.</p>

<h6>8. Disposições Gerais</h2>
<p>8.1 Os Sites e Aplicativos estarão disponíveis para utilização durante 24 (vinte e quatro) horas por dia, 7 (sete) dias por semana, salvo eventuais interrupções que se façam necessárias por ocasião de manutenção da infraestrutura técnica, do sistema ou da base de dados, por falhas em decorrência da falta de fornecimento de energia elétrica e/ou telecomunicação, por casos fortuitos e força maior, ou ainda, por ações ou omissões de terceiros.</p>

<p>8.2 É vedado ao Usuário ceder, transferir ou de qualquer forma alienar, total ou parcialmente, as obrigações e/ou direitos deste Contrato a terceiros.</p>

<p>8.3 O Usuário concorda que do presente Contrato não resulta, em nenhuma hipótese, qualquer vínculo empregatício, societário ou relação de representação dele com a PLUG.</p>

<p>8.4 O Usuário concorda com a eventual cessão ou transferência das obrigações e direitos previstos neste Contrato pela PLUG a outras empresas, incluindo, controladores da PLUG, empresas por ela controlada, a ela coligada ou por qualquer forma a ela associada, no Brasil ou no exterior; ou, ainda, em caso de fusão, aquisição (total ou parcial) e incorporação.</p>

<p>8.5 O Usuário concorda que quaisquer tolerâncias, concessões ou omissões por parte da PLUG não importarão em alterações ou novações deste Contrato, nem constituirão precedentes invocáveis no presente ou no futuro.</p>

        <h6>9. Canais de Comunicação</h2>
<p>9.1 Em casos de dúvidas sobre estes Termos de Uso ou sobre os serviços prestados ou produtos fornecidos através de nossos Sites e Aplicativos, o Usuário poderá contatar os canais de comunicação específicos de cada Site ou Aplicativo, ou ainda, poderá enviar e-mail para contato@plugseguros.com.br</p>

<p>9.2 Em caso de dúvidas ou relatos de problemas relacionados ao tratamento de seus Dados Pessoais, conforme disposto em nossa Política de Privacidade, o Usuário poderá enviar e-mail para privacidade@plugseguros.com.br.</p>

<h6>10. Foro e Legislação Aplicável</h2>
<p>10.1 Para dirimir as dúvidas decorrentes deste Contrato, bem como para dirimir as controvérsias não passíveis de transação, fica eleito o Foro da Comarca da Cidade de São Paulo, Estado de São Paulo, com renúncia a qualquer outro, por mais privilegiado que seja.</p>

<p>10.2 O presente Contrato será regido e interpretado de acordo com a legislação da República Federativa do Brasil.</p>

<p>Última atualização em 10 de janeiro de 2024.</p>`;


export { formatarDataBrasileira, formatDate, formatarCNPJ, getCookie, truncarTexto, TermosUso };
