import React from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBTable,
  MDBTableBody,
} from "mdb-react-ui-kit";
import { NavbarApolice } from "../components/navbar";
import { Footer } from "../components/footer";
import { formatarDataBrasileira, formatarCNPJ } from "../components/utils";

function Apolice({ data }) {
  // Cálculo do Prêmio Líquido
  const premioLiquido = data.vl_total / 1.0738;

  return (
    <>
      <NavbarApolice />
      <MDBContainer className="mt-5">
        <MDBRow>
          <MDBCol md="12">
            <MDBCard>
              <MDBCardBody>
                {/* Detalhes da Apólice */}
                <MDBCardTitle className="mt-4">
                  Detalhes da Apólice:
                </MDBCardTitle>
                <MDBTable>
                  <MDBTableBody>
                    <tr>
                      <td>Número da Apólice:</td>
                      <td>{data.numero_apolice} - {data.nr_endosso}</td>
                    </tr>
                    <tr>
                      <td>Número da Proposta:</td>
                      <td>{data.nr_proposta}</td>
                    </tr>
                    <tr>
                      <td>Data de Emissão:</td>
                      <td>{formatarDataBrasileira(data.dt_emissao)}</td>
                    </tr>
                    <tr>
                      <td>Situação de Apólice:</td>
                      <td>{data.nm_status_proposta}</td>
                    </tr>
                  </MDBTableBody>
                </MDBTable>

                {/* Detalhes do Segurado */}
                <MDBCardTitle className="mt-4">Detalhes do Segurado:</MDBCardTitle>
                <MDBTable>
                  <MDBTableBody>
                    <tr>
                      <td>Nome do Segurado:</td>
                      <td>{data.nome_segurado}</td>
                    </tr>
                    <tr>
                      <td>CPF/CNPJ:</td>
                      <td>{formatarCNPJ(data.CPF_CNPJ)}</td>
                    </tr>
                  </MDBTableBody>
                </MDBTable>

                {/* Detalhes de Endereço */}
                <MDBCardTitle className="mt-4">Detalhes do Empreendimento:</MDBCardTitle>
                <MDBTable>
                  <MDBTableBody>
                    <tr>
                      <td>Nome do Empreendimento:</td>
                      <td>{data.nome_empreendimento}</td>
                    </tr>
                    <tr>
                      <td>Endereço Completo:</td>
                      <td>{`${data.Endereco}, ${data.No_endereco}${(data.Complemento !== 'NULL' && data.Complemento !== '') ? ', ' + data.Complemento : ''}`}</td>
                    </tr>
                    <tr>
                      <td>Cidade:</td>
                      <td>{data.Cidade}</td>
                    </tr>
                    <tr>
                      <td>Estado:</td>
                      <td>{data.Estado}</td>
                    </tr>
                    <tr>
                      <td>CEP:</td>
                      <td>{data.CEP}</td>
                    </tr>
                  </MDBTableBody>
                </MDBTable>

                {/* Detalhes do Imóvel */}
                <MDBCardTitle className="mt-4">Detalhes do Imóvel:</MDBCardTitle>
                <MDBTable>
                  <MDBTableBody>
                    <tr>
                      <td>Unidade Habitacional:</td>
                      <td>{data.unidade_habitacional}</td>
                    </tr>
                    <tr>
                      <td>Valor do Imóvel:</td>
                      <td>
                        {Number(data.valor_imovel).toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        }) || "N/A"}
                      </td>
                    </tr>
                    <tr>
                      <td>Número de Matrícula:</td>
                      <td>{data.nr_matricula_imovel}</td>
                    </tr>
                  </MDBTableBody>
                </MDBTable>

                {/* Detalhes de Pagamento e Status */}
                <MDBCardTitle className="mt-4">
                  Detalhes de Pagamento e Status:
                </MDBCardTitle>
                <MDBTable>
                  <MDBTableBody>
                    <tr>
                      <td>Forma de Pagamento:</td>
                      <td>{data.nm_meio_pagto}</td>
                    </tr>
                    <tr>
                      <td>Prêmio Líquido:</td>
                      <td>
                        {premioLiquido.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </td>
                    </tr>
                    <tr>
                      <td>IOF (7,38%):</td>
                      <td>
                        {(data.vl_total - premioLiquido).toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </td>
                    </tr>
                    <tr>
                      <td>Prêmio Total:</td>
                      <td>
                        {Number(data.vl_total).toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </td>
                    </tr>
                    <tr>
                      <td>Data de Pagamento:</td>
                      <td>{formatarDataBrasileira(data.dt_pagamento)}</td>
                    </tr>
                    <tr>
                      <td>Situação de Pagamento:</td>
                      <td>{data.nm_status_pagto}</td>
                    </tr>
                    <tr>
                      <td>Data de Cancelamento:</td>
                      <td>{formatarDataBrasileira(data.dt_cancelamento)}</td>
                    </tr>
                  </MDBTableBody>
                </MDBTable>

                {/* Outros Detalhes */}
                <MDBCardTitle className="mt-4">Outros Detalhes:</MDBCardTitle>
                <MDBTable>
                  <MDBTableBody>
                    <tr>
                      <td>Data do Alvará de Construção:</td>
                      <td>
                        {formatarDataBrasileira(data.dt_alvaraconstrucao)}
                      </td>
                    </tr>
                    <tr>
                      <td>Data do Último Endosso:</td>
                      <td>{formatarDataBrasileira(data.dt_ultimoendosso)}</td>
                    </tr>
                    <tr>
                      <td>Email para Envio do Boleto:</td>
                      <td>{data.email_envio_boleto}</td>
                    </tr>
                  </MDBTableBody>
                </MDBTable>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <Footer />
    </>
  );
}

export default Apolice;
