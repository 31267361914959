// dataSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const dataSlice = createSlice({
  name: 'data',
  initialState: {
    value: [],         // Dados
    filters: [],       // Filtros
    sortBy: 'numero_apolice',  // Critério de ordenação
    sortOrder: 'desc'  // Ordem de ordenação
  },
  reducers: {
    // Reducer para atualizar os dados
    setData: (state, action) => {
      state.value = action.payload;
    },

    // Reducer para adicionar um filtro ou atualizar um filtro existente
    addFilter: (state, action) => {
      const { type, value } = action.payload;

      if (type === "nm_status_proposta") {
        // Verifica se já existe um filtro com o mesmo valor
        const existingFilter = state.filters.find(filter => filter.type === type && filter.value === value);

        if (!existingFilter) {
          // Adiciona o novo filtro se ele não existir
          state.filters.push({ type, value });
        }
        // Se o filtro já existir, não faz nada (ignora a adição)
      } else {
        // Para outros tipos de filtro, substitui ou adiciona
        const existingFilterIndex = state.filters.findIndex(filter => filter.type === type);

        if (existingFilterIndex !== -1) {
          // Substitui o filtro existente
          state.filters[existingFilterIndex] = { type, value };
        } else {
          // Adiciona um novo filtro
          state.filters.push({ type, value });
        }
      }
    },

    // Reducer para remover um filtro
    removeFilter: (state, action) => {
      state.filters.splice(action.payload, 1);
    },

    // Reducer para definir o critério de ordenação
    setSortBy: (state, action) => {
      state.sortBy = action.payload;
    },

    // Reducer para definir a ordem de ordenação
    setSortOrder: (state, action) => {
      state.sortOrder = action.payload;
    },

    // Outros reducers relacionados a 'data' podem ser adicionados aqui
  },
});

export const { setData, addFilter, removeFilter, setSortBy, setSortOrder } = dataSlice.actions;
export default dataSlice.reducer;
