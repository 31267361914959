import { useState, useEffect } from 'react';
import { NavbarSticky } from "../components/navbar";
import { Footer } from "../components/footer";
import { CampaingListActive } from '../components/campaignList';
import { getCampaignList } from '../controllers/campaingController';

function Campaign() {
    const [data, setData] = useState([]);

    // Função para carregar dados da campanha
    const loadCampaignData = async () => {
        try {
            const result = await getCampaignList();
            if (!result.success) {
                console.error("Falha ao carregar campanhas");
                return { userCampaigns: [], availableCampaigns: [] };
            }
            return result.list;
        } catch (error) {
            console.error("Erro ao carregar campanhas:", error);
            return { userCampaigns: [], availableCampaigns: [] };
        }
    };

    // Função para mapear campanhas
    const mapCampaigns = (campaignData) => {
        // Mapear campanhas disponíveis
        const availableCampaigns = campaignData.availableCampaigns.map(campaign => ({
            ...campaign,
            EstouInscrito: false
        }));

        // Mapear campanhas do usuário
        const userCampaigns = campaignData.userCampaigns.map(campaign => ({
            ...campaign,
            EstouInscrito: true
        }));

        // Combinar as duas listas
        return [...availableCampaigns, ...userCampaigns];
    };

    useEffect(() => {
        document.title = 'Campanhas - Seguros RCPM';
        (async () => {
            const campaignData = await loadCampaignData();
            const allCampaigns = mapCampaigns(campaignData);
            setData(allCampaigns);
        })();
    }, []);

    return (
        <>
            <NavbarSticky local={'campaign'} />
            <CampaingListActive data={data} />
            <Footer />
        </>
    );
}

export default Campaign;
