import React, { useState } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBDropdownItem,
} from "mdb-react-ui-kit";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useDispatch } from "react-redux";
import { addFilter } from "../controllers/dataSlice";

const FilterForm = ({ sortBy, setSortBy }) => {
  const dispatch = useDispatch();
  const animatedComponents = makeAnimated();

  const [currentFilter, setCurrentFilter] = useState({ type: "", value: "" });
  const [dateRange, setDateRange] = useState({ from: null, to: null });
  const [selectedDateOption, setSelectedDateOption] = useState("");
  const [dataPickerView, setDataPickerView] = useState(false);

  const sortTypeLabels = {
    numero_apolice: "Número de Apólice",
    dt_emissao: "Data de Emissão",
    dt_vencimento: "Data de Vencimento",
    dt_pagamento: "Data de Pagamento",
    CPF_CNPJ: "CNPJ Segurado",
    nm_login: "Login",
    nome_empreendimento: "Nome Empreendimento",
  };

  const selectOptions = [
    { value: "Vigente", label: "Vigente" },
    { value: "Confirmação de pagamento", label: "Confirmação de Pagamento" },
    { value: "Cancelado(a)", label: "Cancelado (a)" },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;

    const formattedValue =
      currentFilter.type === "CPF_CNPJ" ? value.replace(/\D/g, "") : value;

    setCurrentFilter({
      ...currentFilter,
      [name]: formattedValue,
    });
    console.log("Current Filter after update:", currentFilter);
  };

  const handleSortChange = (value) => {
    setSortBy(value);
  };

  const handleSelectChange = (selectedOptions) => {
    if (selectedOptions) {
      selectedOptions.forEach((o) => {
        dispatch(addFilter({ type: "nm_status_proposta", value: o.value }));
      });
    }
  };
  

  const handleAddFilter = (e) => {
    e.preventDefault();
    if (currentFilter.type && currentFilter.value) {
      dispatch(addFilter(currentFilter));
      setCurrentFilter({ type: "", value: "" }); // Resetar o filtro atual
    }
  };

  const handleDateOptionChange = (option, filterType) => {
    setSelectedDateOption(option);

    if (option === "Personalizado") {
      setDataPickerView(true);
      return;
    }

    const endDate = new Date(); // Data final sempre é a data atual
    let startDate = new Date();

    if (option === "Últimos 7 dias") {
      startDate.setDate(endDate.getDate() - 6);
    } else if (option === "Últimos 30 dias") {
      startDate.setDate(endDate.getDate() - 29);
    } else if (option === "Mês atual") {
      startDate.setDate(1);
    }

    // Formate as datas no formato "YYYY-MM-DD"
    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);

    const newDateRange = `${formattedStartDate} - ${formattedEndDate}`;
    if (filterType) {
      // Despachando a ação para adicionar o filtro
      dispatch(addFilter({ type: filterType, value: newDateRange }));
    }
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setDateRange({ from: start, to: end });

    // Atualizar filtro aqui
    if (start && end) {
      const formattedStart = formatDate(start);
      const formattedEnd = formatDate(end);
      const newDateRange = `${formattedStart} - ${formattedEnd}`;

      // Despachando a ação para adicionar o filtro
      dispatch(addFilter({ type: currentFilter.type, value: newDateRange }));

      setDataPickerView(false);
      setDateRange({ from: null, to: null });
    }
  };

  return (
    <form onSubmit={handleAddFilter}>
      <MDBContainer>
        <MDBRow>
          <MDBCol>
            <h4 className="section-title">
              <span className="icon-circle">
                <i className="las la-file-contract"></i>
              </span>
              &nbsp; Procurar Apólices
            </h4>
            <p className="section-desc">
              Empregue os filtros a seguir para localizar apólices cadastradas
              no sistema. Eles também podem ser combinados para realizar uma
              pesquisa específica.
            </p>
          </MDBCol>
        </MDBRow>
        <MDBRow className="justify-content-center">
          <MDBCol md={3}>
            <div className="form-component">
              <label htmlFor="filterType">Selecione o tipo de filtro:</label>
              <select
                id="filterType"
                name="type"
                value={currentFilter.type}
                onChange={handleChange}
              >
                <option value="">Selecione o tipo de filtro</option>
                <option value="numero_apolice">Número de Apólice</option>
                <option value="dt_emissao">Data de Emissão</option>
                <option value="dt_vencimento">Data de Vencimento</option>
                <option value="dt_pagamento">Data de Pagamento</option>
                <option value="CPF_CNPJ">CNPJ Segurado</option>
                <option value="nome_segurado">Razão Social Segurado</option>
                <option value="nm_login">Login</option>
                <option value="nome_empreendimento">Nome Empreendimento</option>
                <option value="nr_matricula_imovel">Número de Matrícula</option>
                <option value="nm_meio_pagto">Meio de Pagamento</option>
                <option value="nm_status_proposta">Situação da Apólice</option>
              </select>
            </div>
          </MDBCol>
          <MDBCol md={6} className="d-flex align-items-end margin-top-1">
            {["dt_emissao", "dt_vencimento", "dt_pagamento"].includes(
              currentFilter.type
            ) ? (
              <>
                <MDBDropdown>
                  <MDBDropdownToggle className="sort-by-btn">
                    {selectedDateOption || "Selecione um intervalo"}
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>
                    <MDBDropdownItem
                      onClick={() =>
                        handleDateOptionChange("hoje", currentFilter.type)
                      }
                      link
                      childTag="button"
                    >
                      Hoje
                    </MDBDropdownItem>
                    <MDBDropdownItem
                      onClick={() =>
                        handleDateOptionChange(
                          "Últimos 7 dias",
                          currentFilter.type
                        )
                      }
                      link
                      childTag="button"
                    >
                      Últimos 7 dias
                    </MDBDropdownItem>
                    <MDBDropdownItem
                      onClick={() =>
                        handleDateOptionChange(
                          "Últimos 30 dias",
                          currentFilter.type
                        )
                      }
                      link
                      childTag="button"
                    >
                      Últimos 30 dias
                    </MDBDropdownItem>
                    <MDBDropdownItem
                      onClick={() =>
                        handleDateOptionChange("Mês atual", currentFilter.type)
                      }
                      link
                      childTag="button"
                    >
                      Mês atual
                    </MDBDropdownItem>
                    <MDBDropdownItem
                      onClick={() =>
                        handleDateOptionChange(
                          "Personalizado",
                          currentFilter.type
                        )
                      }
                      link
                      childTag="button"
                    >
                      Personalizado
                    </MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
                {selectedDateOption === "Personalizado" && (
                  <>
                    {" "}
                    {dataPickerView ? (
                      <DatePicker
                        selected={dateRange.from}
                        onChange={handleDateChange}
                        startDate={dateRange.from}
                        endDate={dateRange.to}
                        calendarClassName="date-picker_custom"
                        selectsRange
                        inline
                      />
                    ) : null}
                  </>
                )}
              </>
            ) : ["nm_status_proposta"].includes(currentFilter.type) ? (
              <>
                <Select
                  closeMenuOnSelect={true}
                  onChange={handleSelectChange}
                  components={animatedComponents}
                  isMulti
                  options={selectOptions}
                  value={""}
                  placeholder="selecione uma ou mais"
                />
              </>
            ) : (
              <>
                <div className="form-component mr-3 margin-top-1">
                  <label
                    htmlFor="filterValue"
                    className={
                      currentFilter.type === "" ? "filterValue_disabled" : ""
                    }
                  >
                    Agora digite o que deseja buscar:
                  </label>
                  <input
                    id="filterValue"
                    type="text"
                    name="value"
                    placeholder={
                      currentFilter.type === ""
                        ? "selecione um filtro"
                        : "digite aqui..."
                    }
                    value={currentFilter.value}
                    onChange={handleChange}
                    disabled={currentFilter.type === "" ? true : false}
                  />
                </div>
                <MDBBtn
                  color="light"
                  rippleColor="dark"
                  onClick={handleAddFilter}
                  className="btn-default_custom border_custom filter"
                  disabled={currentFilter.type === "" ? true : false}
                >
                  Adicionar novo filtro
                </MDBBtn>
              </>
            )}
          </MDBCol>
          <MDBCol
            md={3}
            className="d-flex justify-content-center align-items-end margin-top-1"
          >
            <div>
              <label className="description-label">Classificar por:</label>
              <MDBDropdown>
                <MDBDropdownToggle className="sort-by-btn">
                  <i className="las la-sort"></i>&nbsp;{" "}
                  {sortBy ? sortTypeLabels[sortBy] : "Classificar por"}
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                  <MDBDropdownItem
                    onClick={() => handleSortChange("numero_apolice")}
                    link
                    childTag="button"
                  >
                    Número de Apólice
                  </MDBDropdownItem>
                  <MDBDropdownItem
                    onClick={() => handleSortChange("dt_pagamento")}
                    link
                    childTag="button"
                  >
                    Data de Pagamento
                  </MDBDropdownItem>
                  <MDBDropdownItem
                    onClick={() => handleSortChange("dt_emissao")}
                    link
                    childTag="button"
                  >
                    Data de Emissão
                  </MDBDropdownItem>
                  <MDBDropdownItem
                    onClick={() => handleSortChange("dt_vencimento")}
                    link
                    childTag="button"
                  >
                    Data de Vencimento
                  </MDBDropdownItem>
                  <MDBDropdownItem
                    onClick={() => handleSortChange("CPF_CNPJ")}
                    link
                    childTag="button"
                  >
                    CNPJ Segurado
                  </MDBDropdownItem>
                  <MDBDropdownItem
                    onClick={() => handleSortChange("nm_login")}
                    link
                    childTag="button"
                  >
                    Login
                  </MDBDropdownItem>
                  <MDBDropdownItem
                    onClick={() => handleSortChange("nome_empreendimento")}
                    link
                    childTag="button"
                  >
                    Nome Empreendimento
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </form>
  );
};

export { FilterForm };
