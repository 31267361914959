import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import RoutesController from "./routes/routeController";
import { Provider } from 'react-redux';
import { store } from './controllers/store'; 

function App() {
  return (
    <Provider store={store}>
      <RoutesController />
    </Provider>
  );
}

export default App;
