import React, { useState, useEffect } from "react";
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBBtn,
} from "mdb-react-ui-kit";
import { ModalPopUpNotificacoes } from "./modal";
import { useNavigate } from "react-router-dom";
import { logout } from "../controllers/authController";

const NavbarSticky = ({ local }) => {
  const goTo = (location) => {
    window.location.href = location;
  };

  const [showNotification, setShowNotification] = useState(false);

  return (
    <>
      <MDBNavbar
        sticky
        light
        expand="md"
        className="navbar-shadow-less default-bg-color navbar-default"
      >
        <MDBContainer>
          <MDBNavbarBrand href="/" className="logo">
            Seguro RCPM
          </MDBNavbarBrand>
          <div className="d-flex align-items-center justify-content-end w-80">
            <MDBBtn
              color="light"
              rippleColor="dark"
              className="border_custom shadow-0 btn-default_custom w-auto"
              onClick={() => setShowNotification(!showNotification)}
            >
              <i className="las la-bell"></i>
            </MDBBtn>

            {local === "invoices" ? (
              <MDBBtn
                color="light"
                rippleColor="dark"
                className="border_custom shadow-0 btn-default_custom"
                onClick={() => goTo("/")}
              >
                <i className="las la-file-contract"></i>&nbsp; <span className="hidden-on-responsive">Seguro RCPM</span>
              </MDBBtn>
            ) : (
              <MDBBtn
                color="light"
                rippleColor="dark"
                className="border_custom shadow-0 btn-default_custom w-auto"
                onClick={() => goTo("/invoices")}
              >
                <i className="las la-money-check-alt"></i>&nbsp; <span className="hidden-on-responsive">Boletos</span>
              </MDBBtn>
            )}
            {local === "campaign" ? (
              <MDBBtn
                color="light"
                rippleColor="dark"
                className="border_custom shadow-0 btn-default_custom"
                onClick={() => goTo("/")}
              >
                <i className="las la-file-contract"></i>&nbsp; <span className="hidden-on-responsive">Seguro RCPM</span>
              </MDBBtn>
            ) : (
              <MDBBtn
                color="light"
                rippleColor="dark"
                className="border_custom shadow-0 btn-default_custom w-auto"
                onClick={() => goTo("/campaign")}
              >
                <i className="las la-gift"></i>&nbsp; <span className="hidden-on-responsive">Campanhas</span>
              </MDBBtn>
            )}
            {local === "profile" ? (
              <MDBBtn
                color="light"
                rippleColor="dark"
                className="border_custom shadow-0 btn-default_custom"
                onClick={() => goTo("/")}
              >
                <i className="las la-file-contract"></i>&nbsp; <span className="hidden-on-responsive">Seguro RCPM</span>
              </MDBBtn>
            ) : (
              <MDBBtn
                color="light"
                rippleColor="dark"
                className="border_custom shadow-0 btn-default_custom"
                onClick={() => goTo("/profile")}
              >
                <i className="las la-user-circle"></i>&nbsp; <span className="hidden-on-responsive">Seu Perfil</span>
              </MDBBtn>
            )}
          </div>
        </MDBContainer>
      </MDBNavbar>
      <ModalPopUpNotificacoes
        status={showNotification}
        setStatus={setShowNotification}
      />
    </>
  );
};

const NavbarApolice = () => {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <>
      <MDBNavbar
        sticky
        light
        expand="md"
        className="navbar-shadow-less default-bg-color navbar-default"
      >
        <MDBContainer>
          <div className="d-flex align-items-center justify-content-end w-80">
            <MDBBtn
              color="light"
              rippleColor="dark"
              className="border_custom btn-default_custom w-auto shadow-0"
              onClick={handleGoBack}
            >
              <i className="las la-angle-left"></i>&nbsp; Voltar
            </MDBBtn>
          </div>
          <MDBNavbarBrand href="/" className="logo">
            Seguro RCPM
          </MDBNavbarBrand>
        </MDBContainer>
      </MDBNavbar>
    </>
  );
};

const NavbarLocked = () => {
  return (
    <>
      <MDBNavbar
        sticky
        light
        expand="md"
        className="navbar-shadow-less default-bg-color navbar-default"
      >
        <MDBContainer>
          <MDBNavbarBrand href="/" className="logo">
            Seguro RCPM
          </MDBNavbarBrand>
          <div className="d-flex align-items-center justify-content-end w-80">
            <MDBBtn
              color="light"
              rippleColor="dark"
              className="border_custom btn-default_custom w-auto shadow-0"
              onClick={() => logout()}
            >
              <i className="las la-sign-out-alt"></i>&nbsp; Desconectar
            </MDBBtn>
          </div>
        </MDBContainer>
      </MDBNavbar>
    </>
  );
};

export { NavbarSticky, NavbarApolice, NavbarLocked };
