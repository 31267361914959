import config from './config';
import Cookies from 'js-cookie';

const fetchApolices = async () => {
    try {
        // Defina a URL do seu endpoint de busca de apólices
        const url = `${config.apiBaseUrl}/apolices/search`;      
        
        // Obtenha o token do cookie
        const token = Cookies.get('token');

        // Defina o cabeçalho Bearer
        const headers = {
            Authorization: `Bearer ${token}`,
        };

        // Configuração da requisição
        const requestOptions = {
            method: 'GET',
            headers: headers,
        };

        // Faça a requisição GET para buscar apólices com o cabeçalho de autorização
        const response = await fetch(url, requestOptions);

        // Verifique se a resposta foi bem-sucedida
        if (response.ok) {
            const data = await response.json();
            const apolices = data.apolices;
            return apolices;
        } else {
            // Lida com erros de requisição, se necessário
            window.location.href = '/access';
            return [];
        }
    } catch (error) {
        // Lida com erros de requisição, se necessário
        console.error('Erro na busca de apólices:', error);
        window.location.href = '/access';
        return [];
    }
};

const fetchInvoices = async () => {
    try {
        // Defina a URL do seu endpoint de busca de apólices
        const url = `${config.apiBaseUrl}/apolices/invoices/pending`;

        // Obtenha o token do cookie
        const token = Cookies.get('token');

        // Defina o cabeçalho Bearer
        const headers = {
            Authorization: `Bearer ${token}`,
        };

        // Configuração da requisição
        const requestOptions = {
            method: 'GET',
            headers: headers,
        };

        // Faça a requisição GET para buscar apólices com o cabeçalho de autorização
        const response = await fetch(url, requestOptions);

        // Verifique se a resposta foi bem-sucedida
        if (response.ok) {
            const data = await response.json();

            let apolices;

            if(data.success){
                apolices = data.apolices;
            }else{
                apolices = [];
            }

            return apolices;

        } else {
            // Lida com erros de requisição, se necessário
            console.error('Erro na busca de apólices:', response.statusText);
            window.location.href = '/access';
            return [];
        }
    } catch (error) {
        // Lida com erros de requisição, se necessário
        console.error('Erro na busca de apólices:', error);
        return [];
    }
};

const updateTime = async () => {
    try {
        // Defina a URL do seu endpoint de busca de apólices
        const url = `${config.apiBaseUrl}/server/check/update-time/apolices`;

        // Obtenha o token do cookie
        const token = Cookies.get('token');

        // Defina o cabeçalho Bearer
        const headers = {
            Authorization: `Bearer ${token}`,
        };

        // Configuração da requisição
        const requestOptions = {
            method: 'GET',
            headers: headers,
        };

        // Faça a requisição GET para buscar apólices com o cabeçalho de autorização
        const response = await fetch(url, requestOptions);

        // Verifique se a resposta foi bem-sucedida
        if (response.ok) {
            const data = await response.json();

            let result;

            if(data.success){
                result = data.updateTime;
            }else{
                result = [];
            }

            return result;

        } else {
            // Lida com erros de requisição, se necessário
            console.error('Erro na busca de apólices:', response.statusText);
            return [];
        }
    } catch (error) {
        // Lida com erros de requisição, se necessário
        console.error('Erro na busca de apólices:', error);
        return [];
    }
}

export { fetchApolices, fetchInvoices, updateTime };
