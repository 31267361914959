import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
} from "mdb-react-ui-kit";
import { LoginFairFaxValidator } from "./loginFairFaxValidator";
import { registerComplementary } from "../controllers/authController";
import Cookies from "js-cookie";

const warningCookie = Cookies.get("messageWarning");

const WarningInfo = ({ info }) => {
  return (
    <>
      <MDBContainer>
        <MDBRow className="justify-content-center">
          <MDBCol md={5}>
            <MDBCard className="card-border_custom mb-5">
              <MDBCardBody>
                <MDBRow className="mb-1">
                  <MDBCol>
                    <h5 className="d-flex align-items-center">
                      <span className="icon-circle_line">
                        <i className="las la-exclamation-circle"></i>
                      </span>{" "}
                      Ops, você ainda não pode visualizar suas apólices...
                    </h5>
                    <p className="tip-text">
                      {warningCookie === "Cadastro Incompleto."
                        ? "Você precisa vincular seu login FairFax abaixo para que possamos efetuar a validação do seu cadastro."
                        : warningCookie === 'Login FairFax com verificação pendente.' 
                        ? "Seu acesso está sendo válidado, esse processo geralmente dura 24 horas."
                        : warningCookie}
                    </p>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    {warningCookie !==
                      "Login FairFax com verificação pendente." && (
                      <LoginFairFaxValidator
                        validatorFunction={registerComplementary}
                        warning={warningCookie}
                      />
                    )}
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
};

export { WarningInfo };
