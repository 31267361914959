import React, { useState } from "react";
import { MDBBtn, MDBSpinner } from "mdb-react-ui-kit";

const LoginForm = ({
  loginFunction,
  codeResquestFunction,
  recoveryFunction,
  setStatus,
  setLoginData,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [codeRecovery, setCodeRecovery] = useState("");
  const [loginStatus, setLoginStatus] = useState({ status: "waiting" });
  const [view, setView] = useState("form-login");
  const [recoveryStatus, setRecoveryStatus] = useState({
    status: "waiting",
    message: "",
  });

  const handleLogin = async () => {
    if (loginStatus.status === "logging in") {
      return false;
    }

    setLoginStatus({ status: "logging in" });

    // Chame a função de login passada como argumento aqui, passando email e senha
    const login = await loginFunction(email, password);

    if (!login.loginStatus) {
      setLoginStatus({ status: login.loginStatus, error: login.error });
      console.log(login);
    } else {
      setLoginStatus({ status: "success" });
      setStatus(true);
    }
    setLoginData(login);
  };

  const handleRequestCodeVerification = async () => {
    if (loginStatus.status === "logging in") {
      return false;
    }

    if(!email){
      return setLoginStatus({ status: false, error: 'Para solicitar uma recuperação de senha digite um e-mail válido.' });
    }

    setLoginStatus({ status: "logging in" });

    // Chame a função de login passada como argumento aqui, passando email e senha
    const code = await codeResquestFunction(email);
    console.log("status_code", code);
    if (!code.success) {
      setLoginStatus({ status: code.success, error: code.message });
    } else {
      setView("codeRecovery");
    }

    setLoginStatus({ status: "waiting" });
  };

  const handleRecoveryPassword = async () => {
    if (recoveryStatus.status === "recovery") {
      return false;
    }

    if(!email){
      return setLoginStatus({ status: false, error: 'Para solicitar uma recuperação de senha digite um e-mail válido.' });
    }
    
    setRecoveryStatus({ status: "recovery" });

    // Chame a função de login passada como argumento aqui, passando email e senha
    const code = await recoveryFunction(email, password, codeRecovery);
    console.log("status_code", code);

    setRecoveryStatus({ status: code.success, message: code.message });
  };

  return (
    <>
      {view === "form-login" ? (
        <>
          <p className="text-access-desc">
            Para acessar nossa área exclusiva para gestão das
            apólices de Seguro RCPM, digite suas informações de login abaixo.
          </p>

          <label className="input-desc" htmlFor="email">
            Seu e-mail
          </label>
          <input
            type="email"
            className="form-control border-radius-10 input-access"
            placeholder="Digite aqui seu e-mail"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            autoComplete="true"
          />

          <label className="input-desc mt-3" htmlFor="senha">
            Sua senha
          </label>
          <input
            type="password"
            className="form-control border-radius-10 input-access"
            placeholder="Digite aqui sua senha"
            id="senha"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="true"
          />
          <div className="forgetPassword">
            <a href="#!" onClick={handleRequestCodeVerification}>
              Esqueceu sua senha?
            </a>
          </div>
          <div className="text-center mt-3">
            <MDBBtn
              onClick={handleLogin}
              className="btn-access-area shadow-0 d-flex justify-content-center align-items-center"
            >
              {loginStatus.status === "logging in" ? (
                <MDBSpinner className="me-2" size="sm">
                  <span className="visually-hidden">Loading...</span>
                </MDBSpinner>
              ) : (
                "Fazer login no portal"
              )}
            </MDBBtn>
          </div>
          {loginStatus.status === false && (
            <p className="hint-access mt-3 text-center">
              {loginStatus.error === "Invalid value"
                ? "Verifique os dados e tente novamente."
                : loginStatus.error}
            </p>
          )}
        </>
      ) : (
        <>
          <p className="text-access-desc">
            Para prosseguir com sua solicitação, por favor digite abaixo o
            código de verificação que foi enviado ao seu e-mail e sua nova senha.
          </p>

          <label className="input-desc" htmlFor="code">
            Código de verificação
          </label>
          <input
            type="text"
            className="form-control border-radius-10 input-access"
            placeholder="Digite seu código de verificação"
            id="code"
            value={codeRecovery}
            onChange={(e) => setCodeRecovery(e.target.value)}
            autoComplete={"false"}
          />

          <label className="input-desc mt-3" htmlFor="senha">
            Sua nova senha
          </label>
          <input
            type="password"
            className="form-control border-radius-10 input-access"
            placeholder="Digite aqui sua senha"
            id="senha"
            value={password}
            autoComplete={"false"}
            onChange={(e) => setPassword(e.target.value)}
          />
          <div className="text-center mt-3">
            <MDBBtn
              onClick={handleRecoveryPassword}
              className="btn-access-area shadow-0 d-flex justify-content-center align-items-center"
            >
              {recoveryStatus.status === "logging in" ? (
                <MDBSpinner className="me-2" size="sm">
                  <span className="visually-hidden">Loading...</span>
                </MDBSpinner>
              ) : (
                "Alterar minha senha"
              )}
            </MDBBtn>
          </div>
          {recoveryStatus.status === false ? (
            <p className="hint-access mt-3 text-center">
              {recoveryStatus.message}
            </p>
          ) : (
            <p className="hint-access text-success mt-3 text-center">
              {recoveryStatus.message}
            </p>
          )}
          <p className="register-account text-center">
          Voltar e{" "}
            <a href="#!" onClick={() => setView("form-login")}>
              fazer login
            </a>
          </p>
        </>
      )}
    </>
  );
};

export { LoginForm };
