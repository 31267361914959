import Cookies from "js-cookie";
import config from './config';

const getCampaignList = async () => {
    try {
        // Defina a URL do seu endpoint de busca de apólices
        const url = `${config.apiBaseUrl}/campaign/list`;

        // Obtenha o token do cookie
        const token = Cookies.get('token');

        // Defina o cabeçalho Bearer
        const headers = {
            Authorization: `Bearer ${token}`,
        };

        // Configuração da requisição
        const requestOptions = {
            method: 'GET',
            headers: headers,
        };

        // Faça a requisição GET para buscar apólices com o cabeçalho de autorização
        const response = await fetch(url, requestOptions);

        // Verifique se a resposta foi bem-sucedida
        if (response.ok) {
            const result = await response.json();

            if (result.success) {
                return result;
            } else {
                return { success: false };
            }

        } else {
            // Lida com erros de requisição, se necessário
            console.error('Erro ao buscar dados de usuário:', response.statusText);

            window.location.href = '/access';

            return { success: false };
        }
    } catch (error) {
        // Lida com erros de requisição, se necessário
        console.error('Erro ao buscar dados de usuário:', error);
        return { success: false };
    }
};

const addUserToCampaign = async (idCampanha, idCategoria) => {
    try {
        // Defina a URL do seu endpoint de busca de apólices
        const url = `${config.apiBaseUrl}/campaign/enter`;

        // Obtenha o token do cookie
        const token = Cookies.get('token');

        // Defina o cabeçalho Bearer
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        };
        

        // Configuração da requisição
        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({ 
                idCampanha, 
                idCategoria
            })
        };

        // Faça a requisição GET para buscar apólices com o cabeçalho de autorização
        const response = await fetch(url, requestOptions);

        console.log(response);

        // Verifique se a resposta foi bem-sucedida
        if (response.ok) {
            const result = await response.json();
    
            console.log(result);

            if (result.data.status) {
                return {success: true};
            } else {
                return { success: false };
            }

        } else {
            // Lida com erros de requisição, se necessário
            console.error('Erro ao efetuar a operação solicitada:', response.statusText);

            if (response.statusText === 'Forbidden') {
                window.location.href = '/access';
            }
            return { success: false };
        }
    } catch (error) {
        // Lida com erros de requisição, se necessário
        console.error('Erro ao efetuar a operação solicitada:', error);
        return { success: false };
    }
};

export { getCampaignList, addUserToCampaign }