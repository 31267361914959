import config from './config';
import Cookies from 'js-cookie';


const login = async (email, password) => {
  const url = `${config.apiBaseUrl}/auth/login`;
  const data = {
    email,
    password,
  };

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const responseData = await response.json();

    if (!response.ok) {
      if (response.status === 400) {
        return { loginStatus: false, error: responseData.errors[0].msg }
      } else if (response.status === 401) {
        return { loginStatus: false, error: responseData.error }
      } else {
        return { loginStatus: false, error: 'Houve um erro desconhecido.' }
      }
    }

    return responseData;
  } catch (error) {
    console.error(error);
    return { loginStatus: false, error: 'Houve um erro desconhecido.' }
  }
};

const requestCodeVerify = async (email) => {
  const url = `${config.apiBaseUrl}/auth/password/recovery`;
  const data = {
    email,
  };

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const responseData = await response.json();

    if (!response.ok) {
      if (response.status === 400) {
        return { loginStatus: false, error: responseData.errors[0].msg }
      } else if (response.status === 401) {
        return { loginStatus: false, error: responseData.error }
      } else {
        return { loginStatus: false, error: 'Houve um erro desconhecido.' }
      }
    }

    return responseData.result;
  } catch (error) {
    console.error(error);
    return { loginStatus: false, error: 'Houve um erro desconhecido.' }
  }
};

const recoveryPassword = async (email, newPass, code) => {
  const url = `${config.apiBaseUrl}/auth/password/recovery`;
  const data = {
    email, 
    newPass, 
    code
  };

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const responseData = await response.json();

    if (!response.ok) {
      if (response.status === 400) {
        return { loginStatus: false, error: responseData.errors[0].msg }
      } else if (response.status === 401) {
        return { loginStatus: false, error: responseData.error }
      } else {
        return { loginStatus: false, error: 'Houve um erro desconhecido.' }
      }
    }

    return responseData.result;
  } catch (error) {
    console.error(error);
    return { loginStatus: false, error: 'Houve um erro desconhecido.' }
  }
};

const registerInitial = async (userData) => {
  const url = `${config.apiBaseUrl}/register/initial`;

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    });

    const responseData = await response.json();

    if (!response.ok) {
      return {registerStatus: false, registerError: responseData.message};
    }

    return {registerStatus: responseData.success};

  } catch (error) {
    console.error("Erro no registro inicial:", error);
    return {registerStatus: false, registerError: 'Houve um erro desconhecido.'};
  }
};

const registerComplementary = async (userData) => {
  const url = `${config.apiBaseUrl}/register/complementary`;

 
  const token = Cookies.get('token');


  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(userData),
    });

    const responseData = await response.json();

    if (!response.ok) {
      return {registerStatus: false, registerError: responseData.message};
    }

    if(responseData.message === 'Cadastro na Fairfax realizado com sucesso.'){
      return {registerStatus: true, step: 'waiting-LFF'};
    }

    return {registerStatus: responseData.success, mail: responseData.mail, step: 'code'};

  } catch (error) {
    console.error("Erro ao atualizar informações:", error);
    return {registerStatus: false, registerError: 'Houve um erro desconhecido.'};
  }
};

const logout = () => {
  Cookies.remove('messageWarning');
  Cookies.remove('token');
  Cookies.remove('userId');
  Cookies.remove('userName');
  window.location.href = '/access';
}


export { login, registerInitial, registerComplementary, logout, requestCodeVerify, recoveryPassword };