import { useState, useEffect } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBListGroup,
  MDBListGroupItem,
  MDBBadge,
} from "mdb-react-ui-kit";
import { FilterForm } from "./FilterForm";
import { formatarDataBrasileira, formatarCNPJ, truncarTexto } from "./utils";
import { updateTime } from "../controllers/apolicesController";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  removeFilter,
  setSortOrder,
  addFilter,
  setSortBy,
} from "../controllers/dataSlice";

const SeachPolicy = ({ cardClass, viewApolice }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const data = useSelector((state) => state.data.value);
  const filters = useSelector((state) => state.data.filters);
  const sortBy = useSelector((state) => state.data.sortBy);
  const sortOrder = useSelector((state) => state.data.sortOrder);

  const [viewMode, setViewMode] = useState("card");
  const [currentPage, setCurrentPage] = useState(1);
  const [lastUpdate, setLastUpdate] = useState("");
  const [showLoadMoreButton, setShowLoadMoreButton] = useState(false);

  const itemsPerPage = 9;

  const lastIndex = currentPage * itemsPerPage;

  const filteredData = data.filter((item) => {
    // Primeiro, filtrar baseado em 'nm_status_proposta'

    const statusFilterValues = filters
      .filter((f) => f.type === "nm_status_proposta")
      .map((f) => f.value);

    if (
      statusFilterValues.length > 0 &&
      !statusFilterValues.includes(item.nm_status_proposta)
    ) {
      return false; // Excluir o item se o status não estiver nos valores do filtro
    }

    // Aplicar outros filtros
    return filters
      .filter((f) => f.type !== "nm_status_proposta")
      .every((singleFilter) => {
        const field = item[singleFilter.type];
        if (!field) return true; // Se o campo não existir no item, passar para o próximo filtro

        if (
          ["dt_emissao", "dt_vencimento", "dt_pagamento"].includes(
            singleFilter.type
          )
        ) {
          const [startDate, endDate] = singleFilter.value
            .split(" - ")
            .map((date) => new Date(date));
          const itemDate = new Date(field);
          return itemDate >= startDate && itemDate <= endDate;
        } else {
          return String(field)
            .toLowerCase()
            .includes(String(singleFilter.value).toLowerCase());
        }
      });
  });

  const filterTypeLabels = {
    numero_apolice: "Número de Apólice",
    dt_emissao: "Data de Emissão",
    dt_vencimento: "Data de Vencimento",
    dt_pagamento: "Data de Pagamento",
    CPF_CNPJ: "CNPJ Segurado",
    nome_segurado: "Razão Social Segurado",
    nm_login: "Login",
    nome_empreendimento: "Nome Empreendimento",
    nm_meio_pagto: "Meio de Pagamento",
    nm_status_proposta: "Situação da Apólice",
    nr_matricula_imovel: "Matrícula do Imóvel",
  };

  const sortedData = [...filteredData].sort((a, b) => {
    if (!sortBy) return 0;

    let valueA = a[sortBy];
    let valueB = b[sortBy];

    // Se for uma data, converte para o tipo Date para comparar
    if (["dt_emissao", "dt_vencimento", "dt_pagamento"].includes(sortBy)) {
      valueA = new Date(valueA);
      valueB = new Date(valueB);
    }

    // Se for um número, converte para Number
    if (sortBy === "vl_total") {
      valueA = Number(valueA);
      valueB = Number(valueB);
    }

    let comparison = 0;
    if (valueA < valueB) {
      comparison = -1;
    } else if (valueA > valueB) {
      comparison = 1;
    }

    return sortOrder === "asc" ? comparison : comparison * -1;
  });

  const displayedData = sortedData.slice(0, lastIndex);

  const totalPremio = filteredData.reduce(
    (total, item) => total + (Number(item.vl_total) || 0),
    0
  );

  const totalPremioFormatado = totalPremio.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  const handleSetSortBy = (value) => {
    dispatch(setSortBy(value));
  };

  const handleLoadMore = () => {
    setCurrentPage(currentPage + 1);
  };

  const handleRemoveFilter = (index) => {
    dispatch(removeFilter(index));
  };

  const changeViewMode = () => {
    setViewMode(viewMode === "card" ? "list" : "card");
  };

  const toggleSortOrder = () => {
    dispatch(setSortOrder(sortOrder === "asc" ? "desc" : "asc"));
  };

  const getStatusColor = (statusApolice) => {
    const statusLower = statusApolice.toLowerCase();

    if (statusLower === "pendente") {
      return "success";
    } else if (statusLower === "cancelado(a)") {
      return "danger";
    } else if (statusLower === "confirmação de pagamento") {
      return "warning";
    } else {
      return "primary";
    }
  };

  const handleAccessApolice = (item) => {
    viewApolice(item);
    navigate("/apolice");
  };


  useEffect(() => {
    async function timeTextUpdate() {
      let response = await updateTime();
      setLastUpdate(formatarDataBrasileira(response));
    }
    timeTextUpdate();
  }, []);

  useEffect(() => {

    const loadMoreItems = () => {
      setCurrentPage(currentPage + 1);
    };
    
    const checkIfShouldLoadMore = () => {
      const { scrollTop, scrollHeight, clientHeight } =
        document.documentElement;
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        // Perto do final da página, carrega mais itens
        loadMoreItems();
      }
    };

    const handleResize = () => {
      // Verifica se ainda há mais dados para carregar quando a página é redimensionada
      checkIfShouldLoadMore();
    };

    window.addEventListener("scroll", checkIfShouldLoadMore);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", checkIfShouldLoadMore);
      window.removeEventListener("resize", handleResize);
    };
  }, [currentPage]);

  useEffect(() => {
    setShowLoadMoreButton(lastIndex < sortedData.length);
  }, [lastIndex, sortedData.length]);

  return (
    <>
      <MDBContainer>
        <MDBRow className="justify-content-center">
          <MDBCol>
            <MDBCard className="card-border_custom mb-5">
              <MDBCardBody>
                <FilterForm
                  onAddFilter={addFilter}
                  sortBy={sortBy}
                  setSortBy={handleSetSortBy}
                />
                <div className="filter-used-container">
                  {filters.map((filter, index) => (
                    <span key={index} className="badge_custom filter">
                      <span className="d-block">
                        <span className="filter-type">
                          {filterTypeLabels[filter.type] || filter.type}
                        </span>
                        <span className="filter-value">
                          {[
                            "dt_emissao",
                            "dt_vencimento",
                            "dt_pagamento",
                          ].includes(filter.type)
                            ? formatarDataBrasileira(filter.value)
                            : filter.value}
                        </span>
                      </span>
                      <button onClick={() => handleRemoveFilter(index)}>
                        <i className="las la-times-circle"></i>
                      </button>
                    </span>
                  ))}
                </div>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow className="">
          <MDBCol md={6}>
            <p className="result-number">
              <i>
                {filteredData.length > 1
                  ? "Localizamos " +
                    filteredData.length +
                    " resultados para a busca."
                  : filteredData.length === 1
                  ? "Localizamos um resultado para a busca."
                  : "Não localizamos nenhuma apólice para a busca."}
              </i>
            </p>
            <p className="result-number">
              <i>
                {filteredData.length >= 1
                  ? `Prêmio total de ${totalPremioFormatado}.`
                  : null}
              </i>
            </p>
          </MDBCol>
          <MDBCol>
            <p className="text-right update-time">
              Última atualização em {lastUpdate}
            </p>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol
            size={12}
            className="justify-content-end align-item-center d-flex mb-3"
          >
            {viewMode === "card" ? (
              <MDBBtn
                onClick={() => changeViewMode()}
                color="light"
                rippleColor="dark"
                className="btn-default_custom border_custom filter btn-change-view"
              >
                {" "}
                <i className="las la-list-ul"></i>
              </MDBBtn>
            ) : viewMode === "list" ? (
              <MDBBtn
                onClick={() => changeViewMode()}
                color="light"
                rippleColor="dark"
                className="btn-default_custom border_custom filter btn-change-view"
              >
                <i className="las la-th-large"></i>
              </MDBBtn>
            ) : null}
            <MDBBtn
              onClick={() => toggleSortOrder()}
              color="light"
              rippleColor="dark"
              className="btn-default_custom border_custom filter btn-change-view"
            >
              <i
                className={`las la-sort-amount-${
                  sortOrder === "asc" ? "down" : "up"
                }`}
              ></i>
            </MDBBtn>
          </MDBCol>
        </MDBRow>
        <MDBRow className="justify-content-center">
          {viewMode === "card" ? (
            <>
              {displayedData &&
                displayedData.map((item, index) => (
                  <MDBCol md={4} key={index}>
                    <MDBCard
                      className={`${cardClass} hover-shadow card-custom`}
                      onClick={() => handleAccessApolice(item)}
                    >
                      <MDBCardBody>
                        <p className={"status-card " + item.nm_status_proposta}>
                          <span className="status-label">
                            Situação de Apólice
                          </span>
                          {item.nm_status_proposta === "Pendente"
                            ? "Pagamento Pendente"
                            : item.nm_status_proposta}
                        </p>
                        <MDBCardTitle className="numero-apolice">
                          Nº de Apólice:
                          <span>{item.numero_apolice || "N/A"}</span>
                        </MDBCardTitle>
                        <MDBCardText className="descricao-apolice">
                          Nº CNPJ do Segurado:{" "}
                          <span>{formatarCNPJ(item.CPF_CNPJ) || "N/A"}</span>
                          Razão Social:{" "}
                          <span>{item.nome_segurado || "N/A"}</span>
                          Emissão:{" "}
                          <span>
                            {formatarDataBrasileira(item.dt_emissao) || "N/A"}
                          </span>
                          Vencimento:{" "}
                          <span>
                            {formatarDataBrasileira(item.dt_vencimento) ||
                              "N/A"}
                          </span>
                          Prêmio:{" "}
                          <span>
                            {Number(item.vl_total).toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            }) || "N/A"}
                          </span>
                          Empreendimento:{" "}
                          <span>{item.nome_empreendimento || "N/A"}</span>
                          Unidade:{" "}
                          <span className="text-truncate">
                            {item.unidade_habitacional || "N/A"}
                          </span>
                          Login: <span>{item.nm_login || "N/A"}</span>
                        </MDBCardText>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                ))}
            </>
          ) : (
            <MDBCol>
              <MDBListGroup style={{ minWidth: "22rem" }}>
                {displayedData &&
                  displayedData.map((item, index) => (
                    <MDBListGroupItem
                      className="d-flex justify-content-between align-items-center cursor-pointer hover-shadow"
                      key={index}
                      onClick={() => handleAccessApolice(item)}
                    >
                      <div className="w-100">
                        <div
                          className="show-on-responsive"
                          style={{ paddingBottom: "2rem" }}
                        >
                          <MDBBadge
                            pill
                            light
                            color={getStatusColor(item.nm_status_proposta)}
                            className="text-uppercase"
                          >
                            {item.nm_status_proposta === "Pendente"
                              ? "Pagamento Pendente"
                              : item.nm_status_proposta}
                          </MDBBadge>
                        </div>
                        <div className="fw-bold text-truncate">
                          Apólice: {item.numero_apolice || "N/A"} | Segurado:{" "}
                          {formatarCNPJ(item.CPF_CNPJ) || "N/A"} -{" "}
                          {truncarTexto(item.nome_segurado, 25) || "N/A"}
                        </div>
                        <div className="text-muted list-view_custom mt-3">
                          <MDBRow>
                            <MDBCol>
                              <span>
                                Emissão: <br />
                                {formatarDataBrasileira(item.dt_emissao) ||
                                  "N/A"}
                              </span>
                            </MDBCol>
                            <MDBCol>
                              <span>
                                Vencimento: <br />
                                {formatarDataBrasileira(item.dt_vencimento) ||
                                  "N/A"}
                              </span>
                            </MDBCol>
                            <MDBCol>
                              <span>
                                Prêmio: <br />
                                {Number(item.vl_total).toLocaleString("pt-BR", {
                                  style: "currency",
                                  currency: "BRL",
                                }) || "N/A"}
                              </span>
                            </MDBCol>

                            <MDBCol className="text-truncate">
                              <span>
                                Empreendimento: <br />
                                {item.nome_empreendimento || "N/A"}
                              </span>
                            </MDBCol>

                            <MDBCol className="text-truncate">
                              <span>
                                Unidade: <br />
                                {item.unidade_habitacional || "N/A"}
                              </span>
                            </MDBCol>

                            <MDBCol>
                              <span>
                                Login: <br />
                                {item.nm_login || "N/A"}
                              </span>
                            </MDBCol>
                          </MDBRow>
                        </div>
                      </div>

                      <MDBBadge
                        pill
                        light
                        color={getStatusColor(item.nm_status_proposta)}
                        className="text-uppercase hidden-on-responsive"
                      >
                        {item.nm_status_proposta === "Pendente"
                          ? "Pagamento Pendente"
                          : item.nm_status_proposta}
                      </MDBBadge>
                    </MDBListGroupItem>
                  ))}
              </MDBListGroup>
            </MDBCol>
          )}
        </MDBRow>
        {showLoadMoreButton && (
          <MDBRow className="justify-content-center">
            <MDBCol className="d-flex justify-content-center align-items-center">
              <MDBBtn
                onClick={handleLoadMore}
                color="light"
                rippleColor="dark"
                className="border_custom shadow-0 btn-default_custom"
              >
                Carregar Mais
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        )}
      </MDBContainer>
    </>
  );
};

export { SeachPolicy };
