import React from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';

const Footer = () => {
    return (
        <MDBFooter className='footer'>
            <MDBContainer>
                <MDBRow className='justify-content-center align-items-center'>
                    <MDBCol>
                        <p className='text-center'>© 2023 Plug Corretora de Seguros LTDA - CNPJ: 25.320.170/0001-84. Todos os direitos Reservados. Versão 1.2.1.</p>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </MDBFooter>
    );
}

export { Footer };