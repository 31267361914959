import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../screens/home";
import Profile from "../screens/profile";
import Invoices from "../screens/invoices";
import Campaign from "../screens/campaign";
import Access from "../screens/access";
import Apolice from "../screens/apolice";
import ApoliceContext from '../components/ApoliceContext';

const RoutesController = () => {
  const [dataApolice, setDataApolice] = useState([]);
  return (
    <ApoliceContext.Provider value={{ dataApolice, setDataApolice }}>
      <Router>
        <Routes>
          <Route path="/" setDataApolice={setDataApolice} element={<Home />} />
          <Route path='/access/:callback?' element={<Access view={'login'}/> } />
          <Route path="/access/register/:callback?" element={<Access view={'register'} />} />
          <Route path="/access/login/:callback?" element={<Access view={'login'} />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/invoices/:filter?" element={<Invoices />} />
          <Route path="/campaign" element={<Campaign />} />
          <Route path="/campaign/terms/:id" element={<Campaign />} />
          <Route path="/apolice" element={<Apolice data={dataApolice} />} />
        </Routes>
      </Router>
    </ApoliceContext.Provider>
  );
};

export default RoutesController;
