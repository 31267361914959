// Configuração para Ambiente de Produção
const config = {
    apiBaseUrl: "https://rygd14e32l.execute-api.sa-east-1.amazonaws.com", // URL da API de produção
    cookieSettings: {
      httpOnly: true, // Use HTTPOnly para maior segurança
      secure: true, // Apenas seguro via HTTPS
      sameSite: "Strict", // Seja estrito com solicitações cross-site
    },
    // Outras configurações de produção, como chaves de API, etc.
};

export default config;