import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { NavbarSticky } from "../components/navbar";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { SearchInvoice } from '../components/invoiceSearch';
import { Footer } from "../components/footer";
import { fetchInvoices } from '../controllers/apolicesController';

function Invoices() {
    const { filter } = useParams();
    const [data, setData] = useState([]);

    useEffect(() => {
        document.title = 'Boletos - Seguro RCPM';

        // Função assíncrona para carregar os dados
        async function loadData() {
            let result = await fetchInvoices();

            if (!result) {
                setData([]);
            } else {
                setData(result);
            }

            console.log(result);
        }

        loadData();
    }, []); 

    return (
        <>
            <NavbarSticky local={'invoices'} />
            <MDBContainer>
                <MDBRow className="align-items-center">
                    <MDBCol md="12" className="home-header">
                        <h1 className="mt-3">Boletos Pendentes </h1>
                        <h6>
                            <i>Aqui você pode conferir os boletos que estão pendentes como aplicar filtros para facilitar sua localização.</i>{" "}
                        </h6>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol>
                        <SearchInvoice data={data} filterParams={filter} />
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
            <Footer />
        </>
    );
}

export default Invoices;
