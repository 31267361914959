import React, { useEffect, useState, useRef, useCallback } from 'react';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBListGroup,
  MDBListGroupItem,
  MDBBadge,
  MDBTooltip,
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBDropdownItem,
} from "mdb-react-ui-kit";
import { formatarDataBrasileira } from "./utils";
import { updateTime } from "../controllers/apolicesController";

const SearchInvoice = ({ data, filterParams }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [lastUpdate, setLastUpdate] = useState("");
  const itemsPerPage = 15;

  const initialFilter =
    filterParams === "vence_hoje"
      ? "Vence Hoje"
      : filterParams === "vence_5_dias"
      ? "Vence em 5 Dias"
      : "";
  const [activeFilters, setActiveFilters] = useState(
    initialFilter ? [initialFilter] : []
  );
  const [sortCriteria, setSortCriteria] = useState("vencimento");

  useEffect(() => {
    if (initialFilter) {
      setActiveFilters([initialFilter]);
    }
  }, [filterParams, initialFilter]);

  const toggleFilter = (filterName) => {
    if (initialFilter && filterName !== initialFilter) {
      setActiveFilters([filterName]);
    } else {
      setActiveFilters(
        activeFilters.includes(filterName)
          ? activeFilters.filter((f) => f !== filterName)
          : [...activeFilters, filterName]
      );
    }
  };

  const filterButtons = [
    "Vence Hoje",
    "Vence em 5 Dias",
    "À Vencer",
    "Vencido",
    "Vencido +60 Dias",
  ];
  const sortTypeLabels = {
    vencimento: "Data de Vencimento",
    segurado: "Segurado",
  };

  const getTodayDateBrasilia = () => new Date().setHours(0, 0, 0, 0);

  const getDiasDeDiferenca = (dataInicial, dataFinal) =>
    Math.floor((dataFinal - dataInicial) / (1000 * 60 * 60 * 24) - 1);

  const checkExpiredDays = (date) =>
    getDiasDeDiferenca(
      new Date(date).setHours(0, 0, 0, 0),
      getTodayDateBrasilia()
    );

  const changeSortCriteria = (newCriteria) => setSortCriteria(newCriteria);

  const filteredData = data.filter((item) => {
    if (!activeFilters.length) return true;

    const diasVencido = checkExpiredDays(item.dt_vencimento);

    const filterMap = {
      "Vence Hoje": diasVencido === 0,
      "À Vencer": diasVencido < 0,
      "Vence em 5 Dias": diasVencido >= -5 && diasVencido < 0,
      Vencido: diasVencido > 0,
      "Vencido +60 Dias": diasVencido > 60,
    };

    return activeFilters.some((filter) => filterMap[filter]);
  });

  const totalValor = filteredData.reduce(
    (total, item) => total + (Number(item.vl_total) || 0),
    0
  );
  const totalValorFormatado = totalValor.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  const getSortedData = (data, criteria) => {
    if (criteria === "vencimento") {
      return [...data].sort(
        (a, b) => new Date(a.dt_vencimento) - new Date(b.dt_vencimento)
      );
    } else if (criteria === "segurado") {
      return [...data].sort((a, b) =>
        a.nome_segurado.localeCompare(b.nome_segurado)
      );
    }
    return data;
  };

  const sortedFilteredData = getSortedData(filteredData, sortCriteria);
  const displayedData = sortedFilteredData.slice(0, currentPage * itemsPerPage);

  useEffect(() => {
    async function timeTextUpdate() {
      let response = await updateTime();
      setLastUpdate(formatarDataBrasileira(response));
    }
    timeTextUpdate();
  }, []);

  const observer = useRef();
  const lastElementRef = useCallback(node => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && currentPage < Math.ceil(data.length / itemsPerPage)) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    });
    if (node) observer.current.observe(node);
  }, [currentPage, data.length, itemsPerPage]);


  return (
    <>
      <MDBContainer>
        <MDBRow className="justify-content-center">
          <MDBCol>
            <MDBCard className="card-border_custom mb-5">
              <MDBCardBody>
                <MDBRow className="mb-1">
                  <MDBCol>
                    <h5>
                      <span className="icon-circle_line">
                        <i className="las la-filter"></i>
                      </span>{" "}
                      Filtrar Boletos
                    </h5>
                    <p className="tip-text">
                      Selecione as opções desejadas abaixo para aplicar os
                      filtros de busca.
                    </p>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    {filterButtons.map((btn) => (
                      <>
                        {btn === "Vencido +60 Dias" ? (
                          <MDBTooltip
                            title="Apólice sujeita a cancelmento automático"
                            tag="span"
                            wrapperProps={{ width: "auto", height: "auto" }}
                          >
                            <button
                              key={"Vencido +60 Dias"}
                              className={`btn btn-invoice_filter shadow-0 margin-top-1 ${
                                activeFilters.includes("Vencido +60 Dias")
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() => toggleFilter("Vencido +60 Dias")}
                            >
                              Vencido +60 Dias
                            </button>
                          </MDBTooltip>
                        ) : (
                          <button
                            key={btn}
                            className={`btn btn-invoice_filter shadow-0 ${
                              activeFilters.includes(btn) ? "active" : ""
                            }`}
                            onClick={() => toggleFilter(btn)}
                          >
                            {btn}
                          </button>
                        )}
                      </>
                    ))}
                  </MDBCol>
                  <MDBCol
                    md={3}
                    className="d-flex justify-content-end margin-top-1"
                  >
                    <div>
                      <label className="description-label">
                        Classificar por:
                      </label>
                      <MDBDropdown>
                        <MDBDropdownToggle className="sort-by-btn">
                          <i className="las la-sort"></i>&nbsp;{" "}
                          {sortCriteria
                            ? sortTypeLabels[sortCriteria]
                            : "Classificar por"}
                        </MDBDropdownToggle>
                        <MDBDropdownMenu>
                          <MDBDropdownItem
                            onClick={() => changeSortCriteria("segurado")}
                            link
                            childTag="button"
                          >
                            Segurado
                          </MDBDropdownItem>
                          <MDBDropdownItem
                            onClick={() => changeSortCriteria("vencimento")}
                            link
                            childTag="button"
                          >
                            Data de Vencimento
                          </MDBDropdownItem>
                        </MDBDropdownMenu>
                      </MDBDropdown>
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow className="">
          <MDBCol md={6}>
            <p className="result-number">
              <i>
                {filteredData.length > 1
                  ? `Localizamos ${filteredData.length} resultados para a busca.`
                  : filteredData.length === 1
                  ? "Localizamos um resultado para a busca."
                  : "Não localizamos nenhuma apólice para a busca."}
              </i>
            </p>
            <p className="result-number">
              <i>
                {filteredData.length >= 1
                  ? `Valor total de ${totalValorFormatado}.`
                  : ""}
              </i>
            </p>
          </MDBCol>
          <MDBCol>
            <p className="text-right update-time">
              Última atualização em {lastUpdate}
            </p>
          </MDBCol>
        </MDBRow>
        <MDBRow className="justify-content-center">
          <MDBCol>
            <MDBListGroup style={{ minWidth: "22rem" }}>
              {displayedData.map((item, index) => (
                <MDBListGroupItem
                  className="d-flex justify-content-between align-items-center cursor-pointer hover-shadow"
                  ref={index === displayedData.length - 1 ? lastElementRef : null}
                  key={index}
                >
                  <div className="w-100">
                    <MDBBadge
                      pill
                      light
                      color={
                        checkExpiredDays(item.dt_vencimento) <= 0
                          ? "primary"
                          : "danger"
                      }
                      className="text-uppercase show-on-responsive"
                    >
                      {item.nm_status_pagto}
                    </MDBBadge>
                    <div className="text-muted list-view_custom mt-3">
                      <MDBRow>
                        <MDBCol>
                          <span>
                            <b>Apólice:</b> <br />
                            {item.numero_apolice || "N/A"}
                          </span>
                        </MDBCol>
                        <MDBCol>
                          <span>
                            <b>Vencimento:</b> <br />
                            {formatarDataBrasileira(item.dt_vencimento) ||
                              "N/A"}
                          </span>
                        </MDBCol>
                        <MDBCol>
                          <span className="text-truncate">
                            <b>Segurado:</b> <br />
                            {item.nome_segurado || "N/A"}
                          </span>
                        </MDBCol>
                        <MDBCol>
                          <span>
                            <b>Emissão:</b> <br />
                            {formatarDataBrasileira(item.dt_emissao) || "N/A"}
                          </span>
                        </MDBCol>
                        <MDBCol>
                          <span>
                            <b>Prêmio:</b> <br />
                            {Number(item.vl_total).toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            }) || "N/A"}
                          </span>
                        </MDBCol>
                      </MDBRow>
                    </div>
                  </div>
                  <MDBBadge
                    pill
                    light
                    color={
                      checkExpiredDays(item.dt_vencimento) <= 0
                        ? "primary"
                        : "danger"
                    }
                    className="text-uppercase hidden-on-responsive"
                  >
                    {item.nm_status_pagto}
                  </MDBBadge>
                </MDBListGroupItem>
              ))}
            </MDBListGroup>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
};

export { SearchInvoice };
